import { useContext, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useStripe } from '@stripe/react-stripe-js';
import { StripeCardElement } from '@stripe/stripe-js';

import { ROUTES } from '@/router/routes';

import {
  BillingError,
  getSubscriptionPlanFrom,
  PaymentError,
  PromotionExpiredError,
  useBillingUseCase,
  usePaymentCard,
} from '@/features/common/billing';
import { useSubscriptionUseCase } from '@/features/common/workspace';
import { FbPixelEvent, fbPixelTrackEvent } from '@/features/system/FbPixel';
import { useAppLogger } from '@/features/system/logger';

import { useDocumentMeta } from '@/hooks';

import { PaymentDetailsContext, PaymentFormValues } from './contexts';
import {
  PaymentFailedReason,
  usePaymentDetailsAnalytic,
  usePaymentErrorSnackbar,
  usePaymentSummary,
} from './hooks';

export interface IPaymentDetailsViewModel {
  summary: ReturnType<typeof usePaymentSummary>;
  isProcessing: boolean;
  onSubmit: () => Promise<void>;
}

export const usePaymentDetailsViewModel = (): IPaymentDetailsViewModel => {
  useDocumentMeta({
    title: 'payment.title',
    description: 'payment.description',
  });

  const { t } = useTranslation('paymentDetails');
  const [isProcessing, setIsProcessing] = useState(false);

  const { currentSubscription } = useContext(PaymentDetailsContext);
  const form = useFormContext<PaymentFormValues>();

  const navigate = useNavigate();

  const stripe = useStripe();

  const analytic = usePaymentDetailsAnalytic();

  useEffect(() => {
    analytic.pageViewed();
  }, []);

  const billingUseCase = useBillingUseCase();
  const subscriptionUseCase = useSubscriptionUseCase();

  const logger = useAppLogger();

  const summary = usePaymentSummary({ currentSubscription });

  const errorSnackbar = usePaymentErrorSnackbar();

  const paymentMethod = form.watch('paymentMethod');

  const shouldValidateCard = paymentMethod == null;

  const { card, validate } = usePaymentCard({
    shouldValidate: form.formState.isSubmitted && shouldValidateCard,
    onError: () => {
      analytic.paymentFailed(PaymentFailedReason.CardDetails);
    },
  });

  const createSubscription = async (
    formValues: PaymentFormValues,
    card?: Nullable<StripeCardElement>,
  ): Promise<void> => {
    if (!stripe) {
      throw new PaymentError('We have issues with payment provider loading.');
    }

    if (summary.reciept.total < 0.5) {
      throw new PaymentError('Payment amount is too low. Monimum amount is $0.50');
    }

    if (!formValues.paymentMethod) {
      await validate();
    }

    await billingUseCase.updateBillingDetails(formValues.billingDetails);

    const plan = getSubscriptionPlanFrom(formValues);

    const { secret } = await subscriptionUseCase.create({
      plan,
      billingDetailsFilled: true,
      promoCode: formValues.promotionCode,
      quantity: formValues.seats,
    });

    if (secret) {
      const { error } = await stripe.confirmCardPayment(secret, {
        // @ts-ignore
        payment_method: formValues.paymentMethod?.id ?? {
          card: card,
          billing_details: {
            name: formValues.billingDetails.name,
          },
        },
        setup_future_usage: 'off_session',
        save_payment_method: true,
      });

      if (error) {
        throw PaymentError.fromStripeError(error);
      }
    }

    await subscriptionUseCase.update({
      plan,
      billingDetailsFilled: true,
    });

    analytic.paymentSuccess({
      planType: formValues.planType,
      billingCycle: formValues.billingCycle,
    });

    fbPixelTrackEvent({
      type: FbPixelEvent.Purchase,
      options: { value: summary.reciept.total.toString(), currency: 'USD' },
    });

    navigate(ROUTES.SETTINGS.SUBSCRIPTION, { state: { justSubscribed: true } });
  };

  const updateSubscription = async (formValues: PaymentFormValues): Promise<void> => {
    const plan = getSubscriptionPlanFrom(formValues);

    await billingUseCase.updateBillingDetails(formValues.billingDetails);

    await subscriptionUseCase.update({
      plan,
      billingDetailsFilled: true,
      promoCode: formValues.promotionCode,
      quantity: formValues.seats,
    });

    analytic.paymentSuccess({
      planType: formValues.planType,
      billingCycle: formValues.billingCycle,
    });

    navigate(ROUTES.SETTINGS.SUBSCRIPTION);
  };

  const handlePaymentError = (error: unknown): void => {
    logger.error(error);

    if (error instanceof PromotionExpiredError) {
      summary.onPromotionCodeRemove();
      errorSnackbar.show(t('promotionCode.expired'));
    } else if (error instanceof PaymentError || error instanceof BillingError) {
      errorSnackbar.show(error.message);
    } else {
      errorSnackbar.show();
    }

    analytic.paymentFailed(PaymentFailedReason.ServerError);
  };

  const handleSubmit = form.handleSubmit(
    async (data) => {
      try {
        setIsProcessing(true);

        if (currentSubscription.stripeCustomerId) {
          await updateSubscription(data);
        } else {
          await createSubscription(data, card);
        }
      } catch (error) {
        handlePaymentError(error);
      } finally {
        setIsProcessing(false);
      }
    },
    async () => {
      if (!form.formState.isValid) {
        analytic.paymentFailed(PaymentFailedReason.EmptyFields);
      }
    },
  );

  return {
    summary,
    isProcessing,
    onSubmit: handleSubmit,
  };
};
