import { useTranslation } from 'react-i18next';

import { IRecieptDiscountInfo } from '../../../domain/types';
import { IRecieptUI } from '../../types';
import { UpcomingInvoiceDiscount } from '../useUpcomingInvoice';

import { useFormatCurrency } from './useFormatCurrency';

type UseDiscounts = ({
  recieptDiscounts,
  upcomingInvoiceDiscounts,
}: {
  recieptDiscounts: IRecieptDiscountInfo[] | undefined;
  upcomingInvoiceDiscounts: UpcomingInvoiceDiscount[];
}) => IRecieptUI['discounts'];

export const useDiscounts: UseDiscounts = ({
  recieptDiscounts = [],
  upcomingInvoiceDiscounts = [],
}) => {
  const { t } = useTranslation('paymentDetails', { keyPrefix: 'summary' });
  const format = useFormatCurrency();

  return [
    ...recieptDiscounts.map((d) => ({
      name: t(`savings.${d.name}`, {
        discount: d.displayPercent ? d.displayPercent : d.percent,
      }),
      amount: d.amount,
      amountFormatted: format(d.amount),
    })),
    ...upcomingInvoiceDiscounts.map((d) => ({
      name: t(d?.name),
      amount: d?.amount,
      amountFormatted: format(d?.amount),
    })),
  ];
};
