import { useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';

import { PickerValue } from '../../types';

dayjs.extend(isBetween);

export const usePickerViewModel = ({
  minDateTimestamp,
  defaultValue,
  onChange,
}: {
  defaultValue: string;
  minDateTimestamp?: number;
  onChange: (value: PickerValue) => void;
}) => {
  const [value, setValue] = useState<PickerValue>(() => {
    const parsedValue: PickerValue = [null, null];
    if (defaultValue) {
      const [startDate, endDate] = defaultValue.split('-');
      parsedValue[0] = dayjs(startDate);
      parsedValue[1] = dayjs(endDate);
    }
    return parsedValue;
  });
  const [open, setOpen] = useState(() => {
    if (value[0] && value[1]) {
      return false;
    }
    return true;
  });

  const minDate = dayjs.unix(minDateTimestamp ?? 0);

  const handleChange = (value: [Dayjs, Dayjs]): void => {
    setValue(value);
    const allValuesExist = value.every((item) => Boolean(item));
    const isFutureDate = (value: Dayjs): boolean => dayjs().isBefore(value);
    const isPastDate = (value: Dayjs): boolean => minDate.isAfter(value);

    const verifiedValue: [Dayjs, Dayjs] = value.map((value): Dayjs => {
      if (value && isFutureDate(value)) {
        return dayjs();
      }

      if (value && isPastDate(value)) {
        return minDate;
      }

      return value;
    }) as [Dayjs, Dayjs];

    if (allValuesExist) {
      onChange(verifiedValue);
    }
  };

  const handleInputClick = (): void => {
    setOpen(true);
  };

  const handelClose = (): void => {
    setOpen(false);
  };

  return {
    value,
    open,
    minDate,
    handleChange,
    handleInputClick,
    handelClose,
  };
};
