import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import { useInjection } from '@/ioc/ioc.react';
import { PAYMENT_DETAILS_TYPES } from '@/ioc/types';

import { IPaymentDetailsUseCase } from '@/features/paymentDetails';

import { formatCurrency } from '@/utils/formatCurrency';
import { useObservableResult } from '@/utils/rx';

export const usePlanCardNextPaymentViewModel = (): Nullable<{
  date: string;
  amount: string;
}> => {
  const {
    i18n: { language },
  } = useTranslation();
  const paymentDetailsUseCase = useInjection<IPaymentDetailsUseCase>(
    PAYMENT_DETAILS_TYPES.PaymentDetailsUseCase,
  );

  const { data: invoice } = useObservableResult(
    () => paymentDetailsUseCase.getInvoiceData(),
    {
      deps: [],
      cacheKey: 'invoice-data',
    },
  );

  if (!invoice) return null;

  return {
    date: dayjs(invoice.created * 1000).format('ll'),
    amount: formatCurrency({
      amount: invoice.total,
      currency: 'USD',
      currencyDisplay: 'symbol',
      locale: language,
    }),
  };
};
