import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { CallToAction } from '@/features/callToAction';
import { useAccount, UserRole } from '@/features/common/account';
import { useCurrentPlanType } from '@/features/common/billing';
import { PlanCardContentBase } from '@/features/dashboard/ui/PlanCard/components/PlanCardContentBase';

import { PlanCardContentManager } from './PlanCardContentManager';

export const PlanCardProfessionalPlan: FC = () => {
  const { t } = useTranslation('plans', { keyPrefix: 'plans' });

  const { account } = useAccount();

  const { data: planType } = useCurrentPlanType();

  const isManagerRole = account?.role === UserRole.Manager;

  return (
    <PlanCardContentBase
      title={t(`${planType}.longName`)}
      hideCreditsData={isManagerRole}
    >
      {isManagerRole ? (
        <PlanCardContentManager />
      ) : (
        <CallToAction
          variant="outlined"
          show={['getCredits', 'getExtension', 'revealContact']}
          size="small"
        />
      )}
    </PlanCardContentBase>
  );
};
