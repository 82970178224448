import { useContext, useMemo } from 'react';

import { PaymentDetailsContext } from '../contexts';

const PREVIOUS_PLAN_RETURN_DESCRIPTION = 'Unused time on'; // discount in case of upgrade
const PRORATED_DISCOUNT_DESCRIPTION = 'Remaining time on';

export type UpcomingInvoiceDiscount = { name: string; amount: number };

type UseUpcomingInvoice = () => {
  total: number;
  discounts: UpcomingInvoiceDiscount[];
};

export const useUpcomingInvoice: UseUpcomingInvoice = () => {
  const { invoiceData } = useContext(PaymentDetailsContext);

  return useMemo(() => {
    if (!invoiceData) {
      return {
        total: 0,
        discounts: [],
      };
    }

    const discounts: UpcomingInvoiceDiscount[] = [];

    const prevPlanReturnData = invoiceData.lines.find((l) =>
      l.description.includes(PREVIOUS_PLAN_RETURN_DESCRIPTION),
    );

    if (prevPlanReturnData) {
      discounts.push({
        name: 'savings.previousPlanRefund',
        amount: Math.abs(prevPlanReturnData.amount),
      });
    }

    const newPlanData = invoiceData.lines.find((l) =>
      l.description.includes(PRORATED_DISCOUNT_DESCRIPTION),
    );

    if (newPlanData) {
      const proratedDiscount =
        newPlanData.plan.amount * newPlanData.quantity - newPlanData.amount;

      discounts.push({
        name: 'savings.proratedDiscount',
        amount: Math.abs(proratedDiscount),
      });
    }

    return {
      discounts,
      total: invoiceData.total,
    };
  }, [invoiceData]);
};
