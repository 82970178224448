import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Button, Typography } from '@mui/material';

import { ActionDisableGuard } from '@/features/common/permissions';

import { Modal } from '@/components';

import SwitchTeamIcon from '@/assets/icons/switchTeamIcon.svg?react';

import { Preloader } from '../preloader';

import { useSwitchTeamDialogViewModel } from './SwitchTeamDialogViewModel';

import styles from './styles.module.scss';

export const SwitchTeamDialog: FC = () => {
  const { t } = useTranslation('dashboard');
  const { name, email, onClose, onSubmit, isOpen, emailHref, isProcessing } =
    useSwitchTeamDialogViewModel();

  return (
    <Modal
      containerClass={styles.paperRoot}
      open={isOpen}
      onClose={onClose}
      disableEscapeKeyDown
    >
      <>
        <div className={styles.container}>
          <div className={styles.titleContainer}>
            <Typography variant="h1">{t('switchTeamDialog.title')}</Typography>
          </div>
          <div className={styles.imgContainer}>
            <SwitchTeamIcon />
          </div>
          <div className={styles.descriptionContainer}>
            <Typography variant="h3">
              <Trans t={t} i18nKey="switchTeamDialog.description">
                {{ name }} (
                <a href={emailHref} className={styles.emailLink}>
                  <>{{ email }}</>
                </a>
                ) has invited you to join their team on Powerlead.
              </Trans>
            </Typography>
          </div>
          <div className={styles.alertContainer}>
            <Typography variant="subtitle1">
              <Trans t={t} i18nKey="switchTeamDialog.warning">
                Accepting the invitation from
                <a href={emailHref}>
                  <>{{ email }}</>
                </a>
                will mean losing access to the prospect information you've collected, but
                we'll email you a link to download that data.
              </Trans>
            </Typography>
          </div>
          <div className={styles.buttonsContainer}>
            <ActionDisableGuard>
              <Button variant="contained" disabled={isProcessing} onClick={onSubmit}>
                {isProcessing ? <Preloader /> : t('switchTeamDialog.switchAccounts')}
              </Button>
            </ActionDisableGuard>
            <Button
              variant="outlined"
              color="info"
              disabled={isProcessing}
              onClick={(e): void => {
                onClose && onClose(e, 'escapeKeyDown');
              }}
            >
              {t('switchTeamDialog.decline')}
            </Button>
          </div>
        </div>
      </>
    </Modal>
  );
};
