import type { IWorkspaceSubscriptionEntity } from '../../domain';
import { SubscriptionPlan } from '../../domain';
import type { IWorkspaceSubscriptionDC } from '../dataContracts';

import { mapCreditsDcToEntity, mapCreditsEntityToDc } from './mapCredits';
import { mapSubscriptionPlanDcToEntity } from './mapSubscriptionPlan';

export const mapWorkspaceSubscriptionDcToEntity = (
  dc: IWorkspaceSubscriptionDC,
): IWorkspaceSubscriptionEntity => {
  return {
    plan: mapSubscriptionPlanDcToEntity(dc.plan),
    futurePlan: dc.future_plan as SubscriptionPlan,
    credits: dc.credits ? dc.credits.map(mapCreditsDcToEntity) : [],
    purchaseType: dc.purchase_type,
    startDate: dc.start_date,
    expirationDate: dc.expiration_date,
    paid: dc.paid,
    isGracePeriod: dc.is_grace_period,
    isCanceled: dc.is_canceled,
    paidMembersCount: dc.paid_members_count,
    billingDetailsFilled: dc.billing_details_filled,
    promoCodeId: dc.promo_code_id,
    isActive: dc.is_active,
    isIndividual: dc.is_individual,
    recurringPrice: dc.recurring_price,
    stripeCustomerId: dc.stripe_customer_id,
  };
};

export const mapWorkspaceSubscriptionEntityToDc = (
  entity: IWorkspaceSubscriptionEntity,
): IWorkspaceSubscriptionDC => {
  return {
    plan: entity.plan,
    future_plan: entity.futurePlan,
    credits: entity.credits.map(mapCreditsEntityToDc),
    purchase_type: entity.purchaseType,
    start_date: entity.startDate,
    expiration_date: entity.expirationDate,
    paid: entity.paid,
    is_grace_period: entity.isGracePeriod,
    is_canceled: entity.isCanceled,
    paid_members_count: entity.paidMembersCount,
    billing_details_filled: entity.billingDetailsFilled,
    promo_code_id: entity.promoCodeId,
    is_active: entity.isActive,
    is_individual: entity.isIndividual,
    recurring_price: entity.recurringPrice,
    stripe_customer_id: entity.stripeCustomerId,
  };
};
