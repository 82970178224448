import { FC } from 'react';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Box, Button, Card, CardContent, Stack, Typography } from '@mui/material';
import clsx from 'clsx';

import { CardBrand, CardIcon } from '@/features/common/billing';

import styles from './styles.module.scss';

export const PaymentDetails: FC = () => {
  return (
    <Card className={styles.card}>
      <CardContent className={styles.content}>
        <Stack direction="row" className={styles.row}>
          <Stack direction="column" className={styles.cardDetails}>
            <Typography component="span" className={clsx(styles.text, styles.grey)}>
              Payment method
            </Typography>
            <Box className={styles.cardDetailsContent}>
              <Box className={styles.cardDetailsBrandIcon}>
                <CardIcon brand={CardBrand.MasterCard} />
              </Box>
              <Typography className={styles.text}>****&nbsp;8256</Typography>
              <Typography className={styles.text}>&nbsp;&nbsp;|&nbsp;&nbsp;</Typography>
              <Typography className={clsx(styles.text, styles.grey)}>
                12/26&nbsp;
              </Typography>
              <Box className={styles.cardDetailsLockIcon}>
                <LockOutlinedIcon />
              </Box>
            </Box>
            <Typography component="span" className={clsx(styles.text, styles.grey)}>
              Next payment: $72.95 on Feb 15, 2023
            </Typography>
          </Stack>
          <Stack direction="column" className={styles.actions}>
            <Button variant="outlined" size="small" color="info">
              <Typography variant="textInButton" fontSize={12} fontWeight={500}>
                Change Card
              </Typography>
            </Button>
            <Button variant="text" size="small" color="info">
              <Typography variant="textInButton" fontSize={12} fontWeight={500}>
                Cancel Subscription
              </Typography>
            </Button>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};
