import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ROUTES } from '@/router/routes';

import { useCurrentWorkspace } from '@/features/common/workspace';

import { CallToActionBase, CallToActionBaseProps } from './CallToActionBase';

type Props = CallToActionBaseProps;

export const InviteTeamMember: FC<Props> = ({ onClick, ...props }) => {
  const { t } = useTranslation('common', { keyPrefix: 'callToActions' });
  const navigate = useNavigate();

  const { data } = useCurrentWorkspace();

  const teamMembers = data?.approvedMembersCount ?? 0;

  const inviteMembersButtonText =
    teamMembers < 2 ? t('inviteTeamMembers') : t('manageMembers');

  const handleClick = (): void => {
    const options =
      teamMembers < 2
        ? {
            state: { isInviteTeamMemberModalOpen: true },
          }
        : undefined;

    navigate(ROUTES.SETTINGS.TEAM_MANAGEMENT, options);
    onClick?.();
  };

  return (
    <CallToActionBase onClick={handleClick} {...props} text={inviteMembersButtonText} />
  );
};
