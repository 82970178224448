import { useInjection } from '@/ioc/ioc.react';
import { PAYMENT_DETAILS_TYPES } from '@/ioc/types';

import {
  BillingCycle,
  IStripePromotionCodeEntity,
  PlanType,
} from '@/features/common/billing';
import type { IPaymentDetailsUseCase } from '@/features/paymentDetails';

import { useObservableResult } from '@/utils/rx';

import { IRecieptUI } from '../../types';
import { useUpcomingInvoice } from '../useUpcomingInvoice';

import { useDiscounts } from './useDiscounts';
import { useProduct } from './useProduct';
import { usePromotion } from './usePromotion';
import { useTax } from './useTax';
import { useTotalPrice } from './useTotalPrice';

type UseReciept = (params: {
  planType: PlanType;
  billingCycle: BillingCycle;
  promotionCode?: IStripePromotionCodeEntity;
  seats: number;
}) => IRecieptUI;

export const useReciept: UseReciept = ({
  planType,
  billingCycle,
  promotionCode,
  seats,
}) => {
  const paymentDetailsUseCase = useInjection<IPaymentDetailsUseCase>(
    PAYMENT_DETAILS_TYPES.PaymentDetailsUseCase,
  );

  const { data: reciept } = useObservableResult(
    () =>
      paymentDetailsUseCase.getReciept({
        planType,
        billingCycle,
        promotionCode,
        seats,
      }),
    {
      deps: [planType, billingCycle, promotionCode, seats],
    },
  );

  const upcomingInvoice = useUpcomingInvoice();
  const total = useTotalPrice({ total: reciept?.total });

  return {
    product: useProduct({ billingCycle, planType, seats, product: reciept?.product }),
    discounts: useDiscounts({
      recieptDiscounts: reciept?.discounts,
      upcomingInvoiceDiscounts: upcomingInvoice.discounts,
    }),
    promotion: usePromotion({ promotion: reciept?.promotion }),
    tax: useTax({ tax: reciept?.tax }),
    ...total,
  } as IRecieptUI;
};
