import { useTranslation } from 'react-i18next';

import { BillingCycle, PlanType } from '@/features/common/billing';
import { billingConfig } from '@/features/common/billing/config';

import { IReciept } from '../../../domain/types';
import { IRecieptUI } from '../../types';

import { useFormatCurrency } from './useFormatCurrency';

type UseProduct = ({
  billingCycle,
  planType,
  seats,
  product,
}: {
  billingCycle: BillingCycle;
  planType: PlanType;
  seats: number;
  product: IReciept['product'] | undefined;
}) => IRecieptUI['product'] | undefined;

export const useProduct: UseProduct = ({ billingCycle, planType, seats, product }) => {
  const { t } = useTranslation('paymentDetails', { keyPrefix: 'summary' });
  const { t: tPlan } = useTranslation('plans', { keyPrefix: 'plans' });
  const format = useFormatCurrency();

  if (!product) return undefined;

  const pricePerUnitTranslationKey =
    billingCycle === BillingCycle.Monthly ? 'baseAmountMonth' : 'baseAmountYear';

  const credits = billingConfig.credits[planType][billingCycle] * seats;

  return {
    name: `${t(billingCycle)} ${tPlan(`${planType}.longName`)}`,
    priceFormatted: format(product.price),
    pricePerUnit: t(pricePerUnitTranslationKey, {
      totalPrice: format(product.pricePerUnit * seats),
      count: seats,
      credits: Number.isFinite(credits) ? credits : 'unlimited',
    }),
  };
};
