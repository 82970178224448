import { useTranslation } from 'react-i18next';

import { IReciept } from '../../../domain/types';
import { IRecieptUI } from '../../types';

import { useFormatCurrency } from './useFormatCurrency';

type UseTax = ({
  tax,
}: {
  tax: IReciept['tax'] | undefined;
}) => IRecieptUI['tax'] | undefined;

export const useTax: UseTax = ({ tax }) => {
  const { t } = useTranslation('paymentDetails', { keyPrefix: 'summary' });
  const format = useFormatCurrency();

  if (!tax || tax.amount <= 0) return undefined;

  return {
    name: t('tax', { tax: tax.percent }),
    amountFormatted: format(tax.amount),
    amount: tax.amount,
  };
};
