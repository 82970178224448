import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, Typography } from '@mui/material';

import { ROUTES } from '@/router/routes';

import { ActionDisableGuard } from '@/features/common/permissions';
import { useIsOnline } from '@/features/system/OnlineTracker';

import { Preloader } from '@/components';

import styles from './styles.module.scss';

const PrimaryAction: FC<{
  children: ReactNode;
  onClick: () => void;
  isLoading?: boolean;
}> = ({ children, onClick, isLoading }) => {
  const isOnline = useIsOnline();
  return (
    <ActionDisableGuard>
      <Button
        variant="contained"
        color="secondary"
        size="small"
        onClick={onClick}
        disabled={!isOnline || isLoading}
      >
        {isLoading ? (
          <Preloader />
        ) : (
          <Typography variant="textInButton">{children}</Typography>
        )}
      </Button>
    </ActionDisableGuard>
  );
};

const SecondaryAction: FC<{
  children: ReactNode;
  onClick: () => void;
  isLoading?: boolean;
}> = ({ children, onClick, isLoading }) => {
  const isOnline = useIsOnline();
  return (
    <ActionDisableGuard>
      <Button
        variant="outlined"
        color="info"
        size="small"
        className={styles.action}
        onClick={onClick}
        disabled={!isOnline || isLoading}
      >
        {isLoading ? (
          <Preloader />
        ) : (
          <Typography variant="textInButton">{children}</Typography>
        )}
      </Button>
    </ActionDisableGuard>
  );
};

type ClickableActionProps = {
  onClick: () => void | Promise<void>;
  isLoading?: boolean;
  variant?: 'primary' | 'secondary';
};

export const UpgradeAction: FC = () => {
  const { t } = useTranslation('settings', {
    keyPrefix: 'subscriptions.planCard.actions',
  });
  const navigate = useNavigate();
  return (
    <PrimaryAction
      onClick={() => {
        navigate(ROUTES.BILLING.PLANS);
      }}
    >
      {t('upgradePlan')}
    </PrimaryAction>
  );
};

export const ResubscribeAction: FC<ClickableActionProps> = ({ onClick, isLoading }) => {
  const { t } = useTranslation('settings', {
    keyPrefix: 'subscriptions.planCard.actions',
  });

  return (
    <PrimaryAction onClick={onClick} isLoading={isLoading}>
      {t('resubscribe')}
    </PrimaryAction>
  );
};

export const CancelSubscriptionAction: FC<ClickableActionProps> = ({ onClick }) => {
  const { t } = useTranslation('settings', {
    keyPrefix: 'subscriptions.planCard.actions',
  });

  return <SecondaryAction onClick={onClick}>{t('cancelSubscription')}</SecondaryAction>;
};

export const ChangeCardAction: FC<ClickableActionProps> = ({ variant, ...props }) => {
  const { t } = useTranslation('settings', {
    keyPrefix: 'subscriptions.planCard.actions',
  });

  if (variant === 'primary') {
    return <PrimaryAction {...props}>{t('changeCard')}</PrimaryAction>;
  }

  return <SecondaryAction {...props}>{t('changeCard')}</SecondaryAction>;
};

export const KeepPlanAction: FC<ClickableActionProps> = ({ onClick, isLoading }) => {
  const { t } = useTranslation('settings', {
    keyPrefix: 'subscriptions.planCard.actions',
  });
  return (
    <PrimaryAction isLoading={isLoading} onClick={onClick}>
      {t('keepPlan')}
    </PrimaryAction>
  );
};
