// @ts-nocheck
import { formatCurrency } from '@/utils/formatCurrency';

interface IDiscount {
  id: string;
  title: string;
  isVirtual?: boolean;
  isOneTime?: boolean;
  applyTo: (amountBefore: number) => number;
}

interface IFixedDiscount extends IDiscount {
  amount: number;
  displayAmount: string;
}

interface IPercentageDiscount extends IDiscount {
  rate: number;
  displayRate: string;
}

abstract class Discount implements IDiscount {
  id: string;
  title: string;
  isVirtual: boolean;
  isOneTime: boolean;
  abstract applyTo(amountBefore: number): number;

  constructor(params: Pick<IDiscount, 'id' | 'title' | 'isVirtual' | 'isOneTime'>) {
    this.id = params.id;
    this.title = params.title;
    this.isVirtual = params.isVirtual ?? true;
    this.isOneTime = params.isOneTime ?? true;
  }
}

class FixedDiscount extends Discount implements IFixedDiscount {
  amount: number;

  get displayAmount(): string {
    return formatCurrency({
      amount: this.amount,
      currency: 'USD',
      locale: window.document.documentElement.lang,
    });
  }

  constructor(
    params: Pick<IFixedDiscount, 'amount' | 'id' | 'title' | 'isVirtual' | 'isOneTime'>,
  ) {
    super(params);

    this.amount = params.amount;
  }

  applyTo(amountBefore: number): number {
    return amountBefore - this.amount;
  }
}

class PercentageDiscount extends Discount implements IPercentageDiscount {
  rate: number;
  displayRate: string;

  constructor(
    params: Pick<
      IPercentageDiscount,
      'rate' | 'displayRate' | 'id' | 'title' | 'isVirtual' | 'isOneTime'
    >,
  ) {
    super(params);

    this.rate = params.rate;
    this.displayRate = params.displayRate;
  }

  applyTo(amountBefore: number): number {
    return amountBefore * (1 - this.rate);
  }
}

const proAnnualDiscount = new PercentageDiscount({
  id: 'proAnnualDiscount',
  title: 'Annual Discount',
  rate: (25 - 19) / 25,
  displayRate: '25%',
  isVirtual: true,
  isOneTime: false,
});

const growthAnnualDiscount = new PercentageDiscount({
  id: 'growthAnnualDiscount',
  title: 'Annual Discount',
  rate: (34 - 25) / 34,
  displayRate: '25%',
  isVirtual: true,
  isOneTime: false,
});

const ultimateAnnualDiscount = new PercentageDiscount({
  id: 'ultimateAnnualDiscount',
  title: 'Annual Discount',
  rate: (59 - 44) / 59,
  displayRate: '25%',
  isVirtual: true,
  isOneTime: false,
});

const advancedAnnualDiscount = new PercentageDiscount({
  id: 'advancedAnnualDiscount',
  title: 'Annual Discount',
  rate: 25 / 100,
  displayRate: '25%',
  isVirtual: true,
  isOneTime: false,
});

export interface IDiscountEntity {
  name: string;
  rate: number;
  isOneTime: boolean;
  displayRate: number;
}

class DiscountEntity implements IDiscountEntity {
  name: IDiscountEntity['name'];
  rate: IDiscountEntity['rate'];
  isOneTime: IDiscountEntity['isOneTime'];
  displayRate: IDiscountEntity['displayRate'];

  constructor(
    name: IDiscountEntity['name'],
    rate: IDiscountEntity['rate'],
    displayRate: number,
    isOneTime: IDiscountEntity['isOneTime'],
  ) {
    this.name = name;
    this.rate = rate;
    this.isOneTime = isOneTime;
    this.displayRate = displayRate;
  }
}

const CURRENT_DISCOUNT_RATE_TO_SHOW = 25;

export const DiscountAnnual = new DiscountEntity(
  'annualDiscount',
  25 / 100,
  CURRENT_DISCOUNT_RATE_TO_SHOW,
  false,
);

export const ProLegacyV1AnnualDiscount = new DiscountEntity(
  'annualDiscount',
  (25 - 19) / 25,
  CURRENT_DISCOUNT_RATE_TO_SHOW,
  false,
);
export const GrowthAnnualDiscount = new DiscountEntity(
  'annualDiscount',
  (34 - 25) / 34,
  CURRENT_DISCOUNT_RATE_TO_SHOW,
  false,
);

export const UltimateAnnualDiscount = new DiscountEntity(
  'annualDiscount',
  (59 - 44) / 59,
  CURRENT_DISCOUNT_RATE_TO_SHOW,
  false,
);

export const DiscountAdvancedAnnual25 = new DiscountEntity(
  'annualDiscount',
  25 / 100,
  CURRENT_DISCOUNT_RATE_TO_SHOW,
  false,
);

export const ProAnnualDiscount = new DiscountEntity(
  'annualDiscount',
  (34 - 25) / 34,
  CURRENT_DISCOUNT_RATE_TO_SHOW,
  false,
);

export const UnlimitedAnnualDiscount = new DiscountEntity(
  'annualDiscount',
  (99 - 69) / 99,
  CURRENT_DISCOUNT_RATE_TO_SHOW,
  false,
);
