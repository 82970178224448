import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Skeleton, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';

import { InvoiceAction } from '../components/InvoiceAction';

export const useBillingHistoryTableColumns = (isLoading = true): GridColDef[] => {
  const { t } = useTranslation('settings', {
    keyPrefix: 'subscriptions.billingHistory',
  });

  if (isLoading) {
    return [
      {
        field: 'date',
        headerName: t('table.header.date'),
        sortable: false,
        width: 160,
        renderCell: (): ReactElement => <Skeleton width={100} height={20} />,
      },
      {
        field: 'type',
        headerName: t('table.header.type'),
        renderCell: (): ReactElement => <Skeleton width={100} height={20} />,
        sortable: false,
        flex: 1,
      },
      {
        field: 'amount',
        width: 150,
        headerName: t('table.header.price'),
        sortable: false,
        renderCell: (): ReactElement => <Skeleton width={100} height={20} />,
      },
      {
        field: 'transactionId',
        type: 'actions',
        width: 50,
        sortable: false,
        renderCell: (): ReactElement => <Skeleton width={20} height={20} />,
      },
    ];
  }

  return [
    {
      field: 'date',
      headerName: t('table.header.date'),
      sortable: false,
      width: 160,
    },
    {
      field: 'type',
      headerName: t('table.header.type'),
      renderCell: (params): ReactElement => (
        <Typography variant="inherit" color="info">
          {params.row.url}
        </Typography>
      ),
      sortable: false,
      flex: 1,
    },
    {
      field: 'amount',
      width: 150,
      headerName: t('table.header.price'),
      sortable: false,
    },
    {
      field: 'transactionId',
      type: 'actions',
      width: 50,
      sortable: false,
      renderCell: (params): ReactElement => {
        return <InvoiceAction url={params.row.url} />;
      },
    },
  ];
};
