import { useTranslation } from 'react-i18next';

import { formatCurrency } from '@/utils/formatCurrency';

type UseFormatCurrency = () => (amount: number) => string;

export const useFormatCurrency: UseFormatCurrency = () => {
  const {
    i18n: { language },
  } = useTranslation();

  return (amount: number) =>
    formatCurrency({
      currency: 'USD',
      amount,
      locale: language,
    });
};
