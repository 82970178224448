import { useTranslation } from 'react-i18next';
import { map } from 'rxjs';

import type { IPlanDetailsEntity } from '@/features/billing/domain/entities/PlanDetailsEntity';
import { useBillingSettingsUseCase } from '@/features/billing/ui/hooks/useBillingSettingsUseCase';

import { useObservableResult } from '@/utils/rx';

type UsePlanOverviewViewModel = () => {
  isLoading: boolean;
  currentPlan: string;
  planCredits: string;
  users: string;
  creditsRenewOn: string;
};

export const usePlanOverviewViewModel: UsePlanOverviewViewModel = () => {
  const billingSettingsUseCase = useBillingSettingsUseCase();
  const { t } = useTranslation('billingSettings', {
    keyPrefix: 'subscriptionOverview.statistics',
  });

  const formatCurrentPlan = (planName: IPlanDetailsEntity['currentPlan']): string => {
    const name = planName.split('_').at(0);
    if (!name) return 'Unknown';
    return `${name.charAt(0).toUpperCase()}${name.slice(1)}`;
  };

  const formatPlanCredits = (data: IPlanDetailsEntity): string => {
    if (data.planCredits === 'unlimited') {
      return t('planCredits.unlimited');
    }

    return t(`planCredits.${data.billingCycle}`, {
      count: data.planCredits,
    });
  };

  const formatUsers = (count: IPlanDetailsEntity['users']): string => {
    return t('users.count', { count });
  };

  const formatRenewAt = (renewAt: IPlanDetailsEntity['creditsRenewOn']): string => {
    if (!renewAt) return t('creditsRenewOn.noRenewal');

    return renewAt.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });
  };

  const planDetails = useObservableResult(() =>
    billingSettingsUseCase.getCurrnetPlanDetails().pipe(
      map((planDetails) => ({
        currentPlan: formatCurrentPlan(planDetails.currentPlan),
        planCredits: formatPlanCredits(planDetails),
        users: formatUsers(planDetails.users),
        creditsRenewOn: formatRenewAt(planDetails.creditsRenewOn),
      })),
    ),
  );

  return {
    isLoading: planDetails.isLoading,
    currentPlan: planDetails.data?.currentPlan ?? '',
    planCredits: planDetails.data?.planCredits ?? '',
    users: planDetails.data?.users ?? '',
    creditsRenewOn: planDetails.data?.creditsRenewOn ?? '',
  };
};
