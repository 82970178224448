import { inject, injectable } from 'inversify';
import { map, Observable, of, switchMap, throwError } from 'rxjs';

import { BILLING_TYPES, WORKSPACE_TYPES } from '@/ioc/types';

import { IWorkspaceRepository, SubscriptionPlan } from '@/features/common/workspace';
import { IWorkspaceEntity } from '@/features/common/workspace';

import { IBillingRepository } from '../data';

import { IBillingUseCase } from './abstractions';
import {
  IBillingDetailsEntity,
  IBillingInvoiceEntity,
  IPaymentMethodEntity,
  IPaymentMethodUpdateSessionEntity,
  IStripePromotionCodeEntity,
} from './entities';
import { PromotionExpiredError, WorkspaceNotFoundError } from './errors';
import { PlanType } from './types';
import { getPlanTypeFromSubscription } from './utils';

@injectable()
export class BillingUseCase implements IBillingUseCase {
  @inject(BILLING_TYPES.BillingRepository)
  private billingRepository: IBillingRepository;

  @inject(WORKSPACE_TYPES.WorkspaceRepository)
  private workspaceRepository: IWorkspaceRepository;

  private getCurrentWorkspaceOrThrow(): Observable<IWorkspaceEntity> {
    return this.workspaceRepository.getCurrentWorkspace().pipe(
      switchMap((workspace) => {
        if (!workspace) {
          return throwError(() => new WorkspaceNotFoundError());
        }

        return of(workspace);
      }),
    );
  }

  getPlanType(): Observable<PlanType> {
    return this.getCurrentWorkspaceOrThrow().pipe(
      map((worksapce) => getPlanTypeFromSubscription(worksapce.subscription)),
    );
  }

  getBillingDetails = (): Observable<IBillingDetailsEntity> => {
    return this.billingRepository.getBillingDetails();
  };

  updateBillingDetails = (
    billingDetails: IBillingDetailsEntity,
  ): Promise<IBillingDetailsEntity> => {
    return this.billingRepository.updateBillingDetails(billingDetails);
  };

  getInvoices(): Observable<IBillingInvoiceEntity[]> {
    return this.billingRepository.getInvoices().pipe(
      map((invoices) => {
        return invoices.sort((a, b) => {
          const dataA = new Date(a.date);
          const dataB = new Date(b.date);
          return dataB.getTime() - dataA.getTime();
        });
      }),
    );
  }

  getPaymentMethod(): Observable<IPaymentMethodEntity> {
    return this.billingRepository.getPaymentMethod();
  }

  updatePaymentMethod(params: {
    successUrl: string;
    cancelUrl: string;
  }): Promise<IPaymentMethodUpdateSessionEntity> {
    return this.billingRepository.updatePaymentMethod(params);
  }

  getPromoCodeInfo(params: {
    code: string;
    plan: SubscriptionPlan;
  }): Observable<IStripePromotionCodeEntity> {
    return this.billingRepository.getPromoCodeInfo(params).pipe(
      switchMap((promoCode) => {
        if (promoCode.expiresAt) {
          const expiresAt = new Date(promoCode.expiresAt);

          const now = new Date();
          if (expiresAt.getTime() < now.getTime()) {
            return throwError(() => new PromotionExpiredError());
          }
        }

        if (promoCode.active === false) {
          return throwError(() => new PromotionExpiredError());
        }

        return of(promoCode);
      }),
    );
  }
}
