import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Tooltip, Typography } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { useSnackbar } from 'notistack';

import { useInjection } from '@/ioc/ioc.react';
import { TEAM_MEMBER_TYPES } from '@/ioc/types';

import { useAccount, UserRole } from '@/features/common/account';
import { ActionDisableGuard } from '@/features/common/permissions';
import { ITeamMemberUseCase } from '@/features/settings';
import { UserStatus } from '@/features/settings/features/teamManagement/ui/TeamManagementViewModel';
import { ANALYTICS_EVENTS, useAnalytics } from '@/features/system/analytics';

import { Modal } from '@/components';
import { Preloader } from '@/components';
import { RubbishBinIcon } from '@/components/Icons';

import ResendEmailIcon from '@/assets/icons/settings/resendEmailIcon.svg?react';

import styles from './styles.module.scss';

export const MemberActions: React.FC<
  GridRenderCellParams & { canManageTeamMembers: boolean }
> = ({ row: { email, invitationStatus, name, role }, canManageTeamMembers }) => {
  const teamMemberUseCase = useInjection<ITeamMemberUseCase>(
    TEAM_MEMBER_TYPES.TeamMemberUseCase,
  );

  const { t } = useTranslation('settings');
  const { account } = useAccount();

  const { enqueueSnackbar } = useSnackbar();
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const analytics = useAnalytics();

  const isCurrentAccount = email === account?.email;
  const isOwner = role === UserRole.Owner;
  const isPending = invitationStatus === UserStatus.PENDING;

  const onResendClick = async (): Promise<void> => {
    try {
      await teamMemberUseCase.resendInvitation({ email, role });
      analytics.trackEvent(ANALYTICS_EVENTS.TEAM_MEMBER_RESEND_INVITATION);
      enqueueSnackbar(t('teamManagement.table.resendInviteToastTitle'), {
        variant: 'success',
        description: t('teamManagement.table.resendInviteToastSubtitle'),
      });
    } catch (error) {
      throw error; // TODO need to handle error
    }
  };

  const onDeleteClick = (): void => {
    setIsDeleteDialogOpen(true);
  };

  const onDeleteClose = (): void => {
    setIsDeleteDialogOpen(false);
  };

  const onConfirmDeleteClick = async (): Promise<void> => {
    try {
      setIsDeleteLoading(true);
      await teamMemberUseCase.deleteTeamMembers([{ email, reassignTo: '' }]);
      analytics.trackEvent(ANALYTICS_EVENTS.REMOVE_TEAM_MEMBER);
      setIsDeleteDialogOpen(false);
    } catch (error) {
      setIsDeleteLoading(false);
      throw error; // TODO need to handle error
    }
  };

  if (isCurrentAccount || !canManageTeamMembers || isOwner) return null;

  return (
    <>
      <Box className="MuiDataGrid-cellContent">
        <Box className={styles.ActionsWrapper}>
          {isPending && (
            <Tooltip
              placement="top"
              className={styles.Action}
              title={t('teamManagement.table.actions.resendInvite')}
            >
              <ActionDisableGuard>
                <Button variant="text" color="info" onClick={onResendClick}>
                  <ResendEmailIcon />
                </Button>
              </ActionDisableGuard>
            </Tooltip>
          )}
          <Tooltip
            placement="top"
            className={styles.Action}
            title={t('teamManagement.table.actions.delete')}
          >
            <ActionDisableGuard>
              <Button variant="text" color="info" onClick={onDeleteClick}>
                <RubbishBinIcon />
              </Button>
            </ActionDisableGuard>
          </Tooltip>
        </Box>
      </Box>
      <Modal
        containerClass={styles.DialogPaper}
        onClose={onDeleteClose}
        open={isDeleteDialogOpen}
      >
        <>
          <Typography className={styles.DialogTitle}>
            {t('teamManagement.table.deleteDialogTitle')}
          </Typography>
          <Typography className={styles.DialogDescription}>
            {t('teamManagement.table.deleteDialogDescription', { name })}
          </Typography>
          <Box className={styles.DialogButtons}>
            <Button variant="text" color="info" onClick={onDeleteClose}>
              {t('teamManagement.table.deleteDialogCancel')}
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={onConfirmDeleteClick}
              disabled={isDeleteLoading}
            >
              {isDeleteLoading ? (
                <Preloader />
              ) : (
                t('teamManagement.table.deleteDialogConfirm')
              )}
            </Button>
          </Box>
        </>
      </Modal>
    </>
  );
};
