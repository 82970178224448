export enum Permission {
  CanAccessAllContact = 'can_access_all_contacts',
  CanAddTags = 'can_add_tags',
  CanEditOtherTags = 'can_edit_others_tags',
  CanEditOwnTags = 'can_edit_own_tags',
  CanManageMembers = 'can_manage_members',
  CanAccessBilling = 'can_access_billing',
  CanUseExtension = 'can_use_extension',
  CanEditIntegrations = 'can_edit_integrations',
  CanRunBulkEnrichment = 'can_run_bulk_enrichment',
  CanManageContactLists = 'can_manage_contact_lists',
  CanManageContactOwner = 'can_manage_contact_owner',
  CanViewProspectTaskProgress = 'can_view_prospect_task_progress',
  CanEnrichCsv = 'can_enrich_csv',
  CanAccessRefferal = 'can_access_refferal',
}
