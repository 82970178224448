import { FC } from 'react';
import { Typography } from '@mui/material';

import styles from './styles.module.scss';

export const SummaryText: FC<{ text: string; notice?: string }> = ({ text, notice }) => {
  return (
    <Typography className={styles.summaryText}>
      {text}
      {notice ? (
        <Typography
          className={styles.notice}
          dangerouslySetInnerHTML={{ __html: notice }}
        />
      ) : null}
    </Typography>
  );
};
