import {
  IBillingDetailsEntity,
  PlanType,
  useCurrentPlanType,
  useGetBillingDetails,
  useUpdateBillingDetails,
} from '@/features/common/billing';
import { useWorkspaceSubscription } from '@/features/common/workspace';

import { ModalControler, useModalController } from '@/hooks';

type BillingDetailsViewModel =
  | {
      isVisible: false;
    }
  | ({
      isVisible: true;
      billingDetails: IBillingDetailsEntity;
      onBillingDetailsUpdate: (biliingDetails: IBillingDetailsEntity) => Promise<void>;
    } & ModalControler<'EditDialog'>);

export function useBillingDetailsViewModel(): BillingDetailsViewModel {
  const editDialogController = useModalController('EditDialog');
  const updateBillingDetails = useUpdateBillingDetails();
  const { data: billingDetails } = useGetBillingDetails();
  const { data: currentPlanType } = useCurrentPlanType();
  const { data: subscription } = useWorkspaceSubscription();

  // do not show for free plan or if billing details are not loaded
  if (
    currentPlanType === PlanType.Free ||
    !billingDetails ||
    !subscription?.billingDetailsFilled
  ) {
    return {
      isVisible: false,
    };
  }

  return {
    isVisible: true,
    billingDetails,
    onBillingDetailsUpdate: async (
      billingDetails: IBillingDetailsEntity,
    ): Promise<void> => {
      await updateBillingDetails(billingDetails);
    },
    ...editDialogController,
  };
}
