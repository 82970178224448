import { FC } from 'react';

import { useExtensionState } from '@/features/common/extension';
import { useOnboardingStepState } from '@/features/common/onboarding/ui';
import { useSubscriptionUseCase } from '@/features/common/workspace';

import { useObservableResult } from '@/utils/rx';

import { CallToActionBaseProps } from './CallToActionBase';
import { GetCredits } from './GetCredits';
import { GetExtension } from './GetExtension';
import { InviteTeamMember } from './InviteTeamMember';
import { RevealContact } from './RevealContact';

const Actions = {
  getExtension: GetExtension,
  revealContact: RevealContact,
  getCredits: GetCredits,
  inviteTeamMember: InviteTeamMember,
};

type Action = keyof typeof Actions;

export type CallToActionToShow = 'auto' | Action | Action[];

type Props = Exclude<CallToActionBaseProps, 'text'> & {
  show?: CallToActionToShow;
};

const useActionToShow = (show: CallToActionToShow = 'auto'): Action | null => {
  const { isCompleted: isRevealedContact } = useOnboardingStepState('revealContact');
  const { isInstalled: isExtensionInstalled } = useExtensionState();
  const subscriptionUseCase = useSubscriptionUseCase();

  const { data: isUnlimitedPlan } = useObservableResult(() =>
    subscriptionUseCase.getIsUnlimitedPlan(),
  );

  const canShow: Record<Action, boolean> = {
    getExtension: !isExtensionInstalled,
    revealContact: !isRevealedContact,
    inviteTeamMember: !!isUnlimitedPlan,
    getCredits: true,
  };

  if (show === 'auto') {
    if (canShow['getExtension']) return 'getExtension';
    if (canShow['revealContact']) return 'revealContact';
    if (canShow['inviteTeamMember']) return 'inviteTeamMember';

    return 'getCredits';
  }

  // passing array is same as passing 'auto' but with specific skipped actions
  if (Array.isArray(show)) {
    if (show.includes('getExtension') && canShow['getExtension']) return 'getExtension';
    if (show.includes('revealContact') && canShow['revealContact'])
      return 'revealContact';
    if (show.includes('inviteTeamMember') && canShow['inviteTeamMember'])
      return 'inviteTeamMember';
    if (show.includes('getCredits')) return 'getCredits';

    return null;
  }

  return show;
};

export const CallToAction: FC<Props> = ({ show, ...props }) => {
  const action = useActionToShow(show);

  if (!action) return null;

  const ActionComponent = Actions[action];

  return <ActionComponent {...props} />;
};
