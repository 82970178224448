import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';

import { Statistic } from '../Statistic';

import { usePlanOverviewViewModel } from './usePlanOverviewViewModel';

import styles from './styles.module.scss';

export const PlanOverview: FC = () => {
  const { t } = useTranslation('billingSettings', {
    keyPrefix: 'subscriptionOverview.statistics',
  });

  const { isLoading, ...items } = usePlanOverviewViewModel();

  return (
    <Grid container className={styles.wrapper}>
      {Object.entries(items).map(([key, value]) => {
        if (isLoading) {
          return (
            <Grid key={key} item xs>
              <Statistic.Preview />
            </Grid>
          );
        }

        return (
          <Grid key={key} item xs>
            <Statistic title={t(`${key}.title`)} value={value} />
          </Grid>
        );
      })}
    </Grid>
  );
};
