import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { Box, Button, FormControlLabel, Typography } from '@mui/material';

import { EXTERNAL_ROUTES } from '@/router/routes';

import { BillingCycle } from '@/features/common/billing';

import { Radio } from '@/components';

import { PaymentFormValues } from '../../contexts';
import { IPaymentDetailsViewModel } from '../../PaymentDetailsViewModel';

import { PromotionCode, SummaryPrice, SummaryRow, SummaryText } from './components';

import styles from './styles.module.scss';

type PaymentSummaryProps = Pick<IPaymentDetailsViewModel, 'summary' | 'isProcessing'>;

export const PaymentSummary: FC<PaymentSummaryProps> = ({ summary, isProcessing }) => {
  const { t: tSummary } = useTranslation('paymentDetails', { keyPrefix: 'summary' });

  const form = useFormContext<PaymentFormValues>();

  function createRadioHandler(billingCycle: BillingCycle): () => void {
    return (): void => {
      form.setValue('billingCycle', billingCycle, {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
      summary.onPromotionCodeRemove();
    };
  }

  return (
    <Box className={styles.summaryCard}>
      <Typography variant="h2" className={styles.summaryTitle}>
        {tSummary('title')}
      </Typography>
      {summary.showBillingCycleSwitcher ? (
        <div className={styles.summaryCycle}>
          <FormControlLabel
            label={
              <Typography className={styles.summaryCycleLabel}>
                {tSummary('payMonthly')}
              </Typography>
            }
            control={
              <Radio
                name="billingCycle"
                value={BillingCycle.Monthly}
                disabled={isProcessing}
                checked={summary.billingCycle === BillingCycle.Monthly}
                onChange={createRadioHandler(BillingCycle.Monthly)}
              />
            }
          />
          <FormControlLabel
            label={
              <Box className={styles.summaryCycleLabelYearly}>
                <Typography className={styles.summaryCycleLabel}>
                  {tSummary('payYearly')}
                </Typography>
                <Typography className={styles.summaryCycleLabelNotice}>
                  {tSummary('annualDiscount', {
                    discount: summary.annualDiscountPercent,
                  })}
                </Typography>
              </Box>
            }
            control={
              <Radio
                name="billingCycle"
                value={BillingCycle.Yearly}
                disabled={isProcessing}
                checked={summary.billingCycle === BillingCycle.Yearly}
                onChange={createRadioHandler(BillingCycle.Yearly)}
              />
            }
          />
        </div>
      ) : null}
      <SummaryRow>
        <SummaryText
          text={summary.reciept.product?.name ?? ''}
          notice={summary.reciept.product?.pricePerUnit ?? ''}
        />
        <SummaryPrice price={summary.reciept.product?.priceFormatted ?? ''} />
      </SummaryRow>
      {summary.reciept.discounts.map((d) => {
        return (
          <SummaryRow key={d.name}>
            <SummaryText text={d.name} />
            <SummaryPrice price={d.amountFormatted} isCrossed />
          </SummaryRow>
        );
      })}
      {summary.reciept?.tax ? (
        <SummaryRow>
          <SummaryText text={summary.reciept.tax.name} />
          <SummaryPrice price={summary.reciept.tax.amountFormatted} />
        </SummaryRow>
      ) : null}
      {summary.promotionsAvailable ? (
        <PromotionCode
          onApply={summary.onPromotionCodeApply}
          onRemove={summary.onPromotionCodeRemove}
          promotionCode={summary.reciept.promotion?.name}
        />
      ) : null}
      <div className={styles.summaryDelimter} />
      <SummaryRow className={styles.total}>
        <SummaryText text={tSummary('total')} />
        <SummaryPrice price={summary.reciept.totalFormatted} isTotal />
      </SummaryRow>
      <Button
        variant="contained"
        color="primary"
        fullWidth
        disabled={isProcessing}
        type="submit"
        form="payment-details"
      >
        {tSummary('subscribe')}
      </Button>
      <Box mb={'20px'} />
      <Typography className={styles.summaryNotice}>
        <Trans
          i18nKey={'privacyAndPolicy'}
          t={tSummary}
          values={{
            period: tSummary(`${summary.billingCycle}Renew`),
          }}
          components={{
            a1: <a href={EXTERNAL_ROUTES.TERMS} target="_blank" rel="noreferrer" />,
            a2: <a href={EXTERNAL_ROUTES.PRIVACY} target="_blank" rel="noreferrer" />,
          }}
        >
          {'By continuing, you agree to Powerlead '}
          <a href={EXTERNAL_ROUTES.TERMS} target="_blank" rel="noreferrer">
            Terms of Use
          </a>
          {' and '}
          <a href={EXTERNAL_ROUTES.PRIVACY} target="_blank" rel="noreferrer">
            Privacy Policy
          </a>
          .{' '}
          {
            'Your payment will be {{period}} auto-renewed until you\ncancel your subscription.'
          }
        </Trans>
      </Typography>
    </Box>
  );
};
