import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Collapse, Skeleton, Typography } from '@mui/material';
import clsx from 'clsx';

import { CallToAction } from '@/features/callToAction';

import { Statistic } from '../Statistic';

import { useCreditsOverviewVideModel } from './useCreditsOverviewViewModel';

import styles from './styles.module.scss';

type CreditsOverviewProps = {
  isCollapsed: boolean;
};

export const CreditsOverview: FC<CreditsOverviewProps> = ({ isCollapsed }) => {
  const { t } = useTranslation('billingSettings', {
    keyPrefix: 'subscriptionOverview.statistics',
  });

  const { isLoading, totalCreditsLeft, bySources } = useCreditsOverviewVideModel();

  return (
    <Box className={styles.table}>
      <Box className={styles.tableRow}>
        <Box className={styles.tableCol}>
          {isLoading ? (
            <Statistic.Preview />
          ) : (
            <Statistic
              title={t('totalCreditsLeft.title')}
              value={
                <Typography fontWeight={400} component="span" fontSize={22}>
                  {totalCreditsLeft}
                </Typography>
              }
            />
          )}
        </Box>
        <Box className={styles.tableCol}></Box>
        <Box className={clsx(styles.tableCol, styles.justifyFlexEnd)}>
          {isLoading ? (
            <Skeleton width={160} height={40} />
          ) : (
            <CallToAction
              variant="outlined"
              show={['getCredits', 'inviteTeamMember']}
              size="small"
            />
          )}
        </Box>
      </Box>
      <Collapse in={!isLoading && !isCollapsed} timeout="auto" unmountOnExit>
        {bySources.plan ? (
          <Box className={styles.tableRow}>
            <Box className={styles.tableCol}>
              <Statistic
                title={t('bySources.creditsTypeTitle')}
                value={bySources.plan.title}
                description={bySources.plan.renewAt ?? ''}
              />
            </Box>
            <Box className={styles.tableCol}>
              <Statistic
                title={t('bySources.creditsLeftTitle')}
                value={bySources.plan.creditsLeft}
                variant={bySources.plan.isOutOfCredits ? 'danger' : 'default'}
              />
            </Box>
            <Box className={clsx(styles.tableCol, styles.justifyFlexEnd)}></Box>
          </Box>
        ) : null}
      </Collapse>
    </Box>
  );
};
