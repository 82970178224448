import { createContext } from 'react';

import { type IAccountEntity } from '@/features/common/account';
import { IBillingDetailsEntity, IPaymentMethodEntity } from '@/features/common/billing';
import {
  type IWorkspaceEntity,
  type IWorkspaceSubscriptionEntity,
} from '@/features/common/workspace';

import { IInvoiceDataEntity } from '../../../domain';

export type PaymentDetailsContextType = {
  account: IAccountEntity;
  currentSubscription: IWorkspaceSubscriptionEntity;
  currentWorkspace: IWorkspaceEntity;
  paymentMethod: Nullable<IPaymentMethodEntity>;
  billingDetails: Nullable<IBillingDetailsEntity>;
  invoiceData: Nullable<IInvoiceDataEntity>;
};

export const PaymentDetailsContext = createContext<PaymentDetailsContextType>({
  get account(): IAccountEntity {
    throw new Error('PaymentDetailsContext is not yet initialized');
  },
  get currentSubscription(): IWorkspaceSubscriptionEntity {
    throw new Error('PaymentDetailsContext is not yet initialized');
  },
  get currentWorkspace(): IWorkspaceEntity {
    throw new Error('PaymentDetailsContext is not yet initialized');
  },
  paymentMethod: null,
  billingDetails: null,
  invoiceData: null,
});
