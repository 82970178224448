import { inject, injectable } from 'inversify';
import { filter, firstValueFrom, map, Observable } from 'rxjs';

import { WORKSPACE_TYPES } from '@/ioc/types';

import { getPlanTypeFromSubscription, PlanType } from '../../billing';
import { isPlanTypeUnlimited } from '../../billing/domain';
import type { IWorkspaceRepository } from '../data/abstractions/WorksapceRepository';

import { SubscriptionPlan } from './types/SubscriptionPlan';
import type { ISubscriptionUseCase } from './abstractions';
import type { ICreditsEntity, IWorkspaceSubscriptionEntity } from './entities';

@injectable()
export class SubscriptionUseCase implements ISubscriptionUseCase {
  @inject(WORKSPACE_TYPES.WorkspaceRepository)
  private workpsaceRepository: IWorkspaceRepository;

  getSubscription(): Observable<IWorkspaceSubscriptionEntity> {
    return this.workpsaceRepository.getCurrentWorkspaceSubscription();
  }

  create(params: {
    plan: SubscriptionPlan;
    billingDetailsFilled?: boolean;
    quantity?: number;
    promoCode?: string;
  }): Promise<{ secret?: string }> {
    return this.workpsaceRepository.createSubscription(params);
  }

  async update(subscription: {
    plan?: SubscriptionPlan;
    isCanceled?: boolean;
    billingDetailsFilled?: boolean;
    quantity?: number;
    promoCode?: string;
  }): Promise<void> {
    await this.workpsaceRepository.updateSubscription(subscription);
  }

  async cancel(): Promise<void> {
    await this.update({ isCanceled: true });
    await firstValueFrom(
      this.getSubscription().pipe(filter((subsription) => subsription.isCanceled)),
    );
  }

  async updatePlan(plan: SubscriptionPlan): Promise<void> {
    await this.update({ plan, billingDetailsFilled: true });

    await firstValueFrom(
      this.getSubscription().pipe(filter((subsription) => subsription.plan === plan)),
    );
  }

  async renew(): Promise<void> {
    await this.update({ isCanceled: false });
    await firstValueFrom(
      this.getSubscription().pipe(filter((subsription) => !subsription.isCanceled)),
    );
  }

  getSubscriptionPlan(): Observable<SubscriptionPlan> {
    return this.getSubscription().pipe(map((subscription) => subscription.plan));
  }

  getSubscriptionPlanType(): Observable<PlanType> {
    return this.getSubscription().pipe(
      map((subscription) => getPlanTypeFromSubscription(subscription)),
    );
  }

  getIsFreePlan(): Observable<boolean> {
    return this.getSubscriptionPlan().pipe(
      map((plan) => {
        return (
          plan === SubscriptionPlan.FreeMonthly ||
          plan === SubscriptionPlan.FreePlusMonthly
        );
      }),
    );
  }

  getIsUnlimitedPlan(): Observable<boolean> {
    return this.getSubscriptionPlanType().pipe(
      map((planType) => isPlanTypeUnlimited(planType)),
    );
  }

  getSubscriptionCreditsInfo(): Observable<ICreditsEntity> {
    return this.getSubscription().pipe(
      map((subscription) => {
        const creditsInfo = subscription.credits.find(
          (credit) => credit.creditType === 'full',
        );

        if (!creditsInfo) {
          throw new Error('Contact credits not found');
        }

        return creditsInfo;
      }),
    );
  }

  getAvailableCreditsCount(): Observable<number> {
    return this.getSubscriptionCreditsInfo().pipe(
      map((creditsInfo) => creditsInfo.limit - creditsInfo.used),
    );
  }
}
