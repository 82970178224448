import { useNavigate } from 'react-router-dom';

import { ROUTES } from '@/router/routes';

import { useUserMenuHandlers } from '@/components/header/hooks';

type ViewModel = {
  onManageCredits: () => void;
  onClose: () => void;
};

export const useAccountCreditsViewModel = (): ViewModel => {
  const navigate = useNavigate();
  const { onClose } = useUserMenuHandlers();

  const onManageCredits = (): void => {
    onClose();
    navigate(ROUTES.SETTINGS.SUBSCRIPTION);
  };

  return {
    onManageCredits,
    onClose,
  };
};
