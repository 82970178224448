import { useCurrentWorkspace } from '@/features/common/workspace';

type UseTeamMembersCardViewModelReturn = {
  teamMembers: number;
  isLoading: boolean;
};

export const useTeamMembersCardViewModel = (): UseTeamMembersCardViewModelReturn => {
  const { data: workspace } = useCurrentWorkspace();

  const teamMembers = workspace?.approvedMembersCount ?? 0;

  return {
    teamMembers,
    isLoading: teamMembers < 1,
  };
};
