import { useContext, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { billingConfig } from '@/features/common/billing/config';
import { isPlanTypeUnlimited } from '@/features/common/billing/domain';
import {
  PaymentDetailsContext,
  PaymentFormValues,
} from '@/features/paymentDetails/ui/contexts';

type Option = {
  label: string;
  value: number;
  disabled: boolean;
};

type UseSeatsCreditsViewModel = (params: { value: number }) => {
  options: Option[];
};

const NUMBER_OF_OPTIONS = 10;

export const useSeatsCreditsViewModel: UseSeatsCreditsViewModel = ({ value }) => {
  const { t } = useTranslation('paymentDetails', { keyPrefix: 'form' });
  const { currentWorkspace: workspace } = useContext(PaymentDetailsContext);
  const form = useFormContext<PaymentFormValues>();
  const planType = form.watch('planType');
  const billingCycle = form.watch('billingCycle');
  const withCredits = !isPlanTypeUnlimited(planType);

  const creditsPerPeriod = billingConfig.credits[planType][billingCycle];

  const minUsers = Math.max(
    workspace.billableMembersCount,
    workspace.subscription.paidMembersCount,
  );

  const options = useMemo(() => {
    const createOption = (usersCount: number): Option => {
      const disabled = usersCount < minUsers;

      return {
        label: t(`creditsUsersOption`, {
          credits: withCredits ? creditsPerPeriod * usersCount : 'unlimited',
          count: usersCount,
        }),
        value: usersCount,
        disabled,
      };
    };

    const result = Array.from({ length: NUMBER_OF_OPTIONS }, (_, index) => {
      return createOption(index + 1);
    });

    // Add minUsers and value to the list of options if they are not there
    if (!result.find((option) => option.value === minUsers)) {
      result.push(createOption(minUsers));
    }

    // Add value to the list of options if it is not there
    if (!result.find((option) => option.value === value)) {
      result.push(createOption(value));
    }

    result.sort((a, b) => a.value - b.value);

    return result;
  }, [creditsPerPeriod, minUsers, withCredits]);

  return {
    options,
  };
};
