import { inject, injectable } from 'inversify';
import { filter, firstValueFrom, map, Observable } from 'rxjs';

import { TEAM_MEMBER_TYPES, WORKSPACE_TYPES } from '@/ioc/types';

import type { IWorkspaceEntity, IWorkspaceRepository } from '@/features/common/workspace';
import {
  ITeamMemberEntity,
  ITeamMemberEntityUI,
  ITeamMemberStateRepository,
  ITeamMemberUseCase,
} from '@/features/settings';

@injectable()
export default class TeamMemberUseCase implements ITeamMemberUseCase {
  @inject(TEAM_MEMBER_TYPES.TeamMemberRepository)
  private repository: ITeamMemberStateRepository;

  @inject(WORKSPACE_TYPES.WorkspaceRepository)
  private workspaceRepository: IWorkspaceRepository;

  getById(id: string): Observable<ITeamMemberEntity | null> {
    return this.workspaceRepository.getCurrentWorkspace().pipe(
      map((workspace) => {
        return workspace?.members.find((member) => member.uuid === id) ?? null;
      }),
    );
  }

  inviteTeamMembers(teamMembers: ITeamMemberEntityUI[]): Promise<IWorkspaceEntity> {
    return this.repository.inviteTeamMembers(teamMembers);
  }

  deleteTeamMembers(
    list: {
      email: string;
      reassignTo: string;
    }[],
  ): Promise<boolean> {
    return this.repository.deleteTeamMembers(list);
  }

  resendInvitation(data: { email: string; role: string }): Promise<IWorkspaceEntity> {
    return this.repository.resendInvitation(data);
  }

  getTeamMembers(): Observable<ITeamMemberEntity[]> {
    return this.workspaceRepository.getCurrentWorkspace().pipe(
      map((workspace) => {
        return workspace?.members ? workspace.members : [];
      }),
    );
  }

  async updateTeamMember(
    data: Pick<ITeamMemberEntity, 'email' | 'role'>,
  ): Promise<IWorkspaceEntity> {
    const workspace = await firstValueFrom(
      this.workspaceRepository.getCurrentWorkspace(),
    );
    if (!workspace) {
      throw new Error('Workspace not found');
    }

    const updatedWorkspace = {
      ...workspace,
      members: workspace.members.map((member) => {
        if (member.email === data.email) {
          member.role = data.role;
        }
        return member;
      }),
    };
    return this.workspaceRepository.updateWorkspace(updatedWorkspace);
  }

  async setMemberAccessAllContacts(value: boolean): Promise<IWorkspaceEntity> {
    const workspace = await firstValueFrom(
      this.workspaceRepository.getCurrentWorkspace(),
    );
    if (!workspace) {
      throw new Error('Workspace not found');
    }

    return this.workspaceRepository.updateWorkspace({
      ...workspace,
      memberAccessAllContacts: value,
    });
  }

  getMemberAccessAllContacts(): Observable<boolean> {
    return this.workspaceRepository.getCurrentWorkspace().pipe(
      filter((workspace): workspace is IWorkspaceEntity => !!workspace),
      map((workspace) => {
        return workspace.memberAccessAllContacts;
      }),
    );
  }
}
