import { inject, injectable } from 'inversify';
import {
  catchError,
  filter,
  firstValueFrom,
  from,
  map,
  Observable,
  switchMap,
  throwError,
} from 'rxjs';

import { BILLING_TYPES, REQUEST_CACHE_TYPES } from '@/ioc/types';

import type { SubscriptionPlan } from '@/features/common/workspace';
import type { IRequestCache } from '@/features/system/requestCache';

import {
  type IBillingDetailsEntity,
  type IBillingInvoiceEntity,
  type IPaymentMethodEntity,
  type IPaymentMethodUpdateSessionEntity,
  type IStripePromotionCodeEntity,
  PromotionNotFoundError,
} from '../domain';

import type { IBillingApiService, IBillingRepository } from './abstractions';
import type { IBillingDetailsDC, IBillingInvoiceDC } from './dataContracts';
import type { IBillingState } from './db';
import {
  mapBillingDetailsDcToEntity,
  mapBillingDetailsEntityToDc,
  mapBillingInvoiceDcToEntity,
  mapPaymentMethodDCtoEntity,
  mapPaymentMethodUpdateSessionDcToEntity,
  mapStripePromotionCodeDcToEntity,
} from './mappers';

@injectable()
export class BillingRepository implements IBillingRepository {
  @inject(BILLING_TYPES.BillingApiService)
  private readonly billingApiService: IBillingApiService;

  @inject(BILLING_TYPES.BillingState)
  private readonly billingState: IBillingState;

  @inject(REQUEST_CACHE_TYPES.InMemoryRequestCache)
  private readonly requestCache: IRequestCache;

  private getLocalBillingDetails(): Observable<IBillingDetailsEntity> {
    return this.billingState.get$('billingDetails').pipe(
      filter((value) => !!value),
      map(mapBillingDetailsDcToEntity),
    );
  }

  private async getRemoteBillingDetails(): Promise<IBillingDetailsDC> {
    const response = await firstValueFrom(this.billingApiService.getBillingInfo());

    await this.billingState.set('billingDetails', () => {
      return response;
    });

    return response;
  }

  getBillingDetails(): Observable<IBillingDetailsEntity> {
    return from(
      this.requestCache.networkFirst({
        key: 'billingDetails',
        fetcher: () => this.getRemoteBillingDetails(),
      }),
    ).pipe(switchMap(() => this.getLocalBillingDetails()));
  }

  async updateBillingDetails(
    details: IBillingDetailsEntity,
  ): Promise<IBillingDetailsEntity> {
    const detailsDC = await firstValueFrom(
      this.billingApiService.updateBillingInfo(mapBillingDetailsEntityToDc(details)),
    );

    const detailsEntity = mapBillingDetailsDcToEntity(detailsDC);

    await this.billingState.set('billingDetails', () => {
      return detailsEntity;
    });

    return detailsEntity;
  }

  private getLocalInvoices(): Observable<IBillingInvoiceEntity[]> {
    return this.billingState.get$('invoices').pipe(
      filter((value) => !!value),
      map((invoices) => invoices.map(mapBillingInvoiceDcToEntity)),
    );
  }

  private async getRemoteInvoices(): Promise<IBillingInvoiceDC[]> {
    const invoices = await firstValueFrom(this.billingApiService.getInvoices());

    await this.billingState.set('invoices', () => {
      return invoices;
    });

    return invoices;
  }

  getInvoices(): Observable<IBillingInvoiceEntity[]> {
    return from(
      this.requestCache.networkFirst({
        key: 'invoices',
        fetcher: () => this.getRemoteInvoices(),
      }),
    ).pipe(switchMap(() => this.getLocalInvoices()));
  }

  getPaymentMethod(): Observable<IPaymentMethodEntity> {
    return this.billingApiService
      .getPaymentMethod()
      .pipe(map(mapPaymentMethodDCtoEntity));
  }

  updatePaymentMethod(params: {
    successUrl: string;
    cancelUrl: string;
  }): Promise<IPaymentMethodUpdateSessionEntity> {
    return firstValueFrom(
      this.billingApiService
        .updatePaymentMethod(params)
        .pipe(map(mapPaymentMethodUpdateSessionDcToEntity)),
    );
  }

  getPromoCodeInfo(params: {
    code: string;
    plan: SubscriptionPlan;
  }): Observable<IStripePromotionCodeEntity> {
    return this.billingApiService
      .getPromoCodeInfo({
        code: params.code,
        label: params.plan,
      })
      .pipe(
        catchError(() => throwError(() => new PromotionNotFoundError())),
        map(mapStripePromotionCodeDcToEntity),
      );
  }
}
