import { useRef, useState } from 'react';
import { number, object, ObjectSchema, string, ValidationError } from 'yup';

import { BillingCycle, CardBrand, PlanType } from '@/features/common/billing';

import { PaymentFormValues } from './types';

export const usePaymentFormSchema = (config: {
  cardError: Nullable<string>;
  minSeats: number;
}): ObjectSchema<PaymentFormValues> => {
  const configRef = useRef(config);

  configRef.current = config;

  const [schema] = useState(() => {
    const PaymentFormSchema: ObjectSchema<PaymentFormValues> = object({
      planType: string<PlanType>().defined(),
      billingCycle: string<BillingCycle>().defined(),
      seats: number()
        .required('seats.required')
        .test((seats) => {
          if (seats < configRef.current.minSeats) {
            return new ValidationError('seats.minSeats', seats, 'seats');
          }

          return true;
        }),
      billingDetails: object({
        name: string()
          .required('name.required')
          .matches(/^([\p{L}]{2,})+\s+([\p{L}\s]{2,})+$/iu, 'name.incorrect')
          .max(200, 'common.maxLength'),
        email: string().nullable(),
        phone: string().nullable(),
        country: string().required('country.required'),
        state: string().optional(),
        city: string()
          .required('city.required')
          .min(2, 'common.minLength')
          .max(42, 'common.maxLength'),
        address: string().required('address.required').max(100, 'common.maxLength'),
        company: string().nullable(),
        postalCode: string().required('postalCode.required').max(20, 'common.maxLength'),
        vatId: string().nullable(),
      }).defined(),
      promotionCode: string().optional(),
      paymentMethod: object({
        id: string().required(),
        card: object({
          brand: string<CardBrand>().required(),
          last4: string().required(),
          expMonth: number().required(),
          expYear: number().required(),
        }),
      })
        .nullable()
        .optional()
        .test(async (a) => {
          if (a == null) {
            if (configRef.current.cardError) {
              return new ValidationError(
                configRef.current.cardError,
                undefined,
                'paymentMethod.card',
              );
            }
          }
          return true;
        }),
    }).nonNullable();

    return PaymentFormSchema;
  });

  return schema;
};
