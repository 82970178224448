import { inject, injectable } from 'inversify';
import { firstValueFrom, map } from 'rxjs';

import { TEAM_MEMBER_TYPES } from '@/ioc/types';

import { IWorkspaceEntity } from '@/features/common/workspace';
import { mapWorkspaceDcToEntity } from '@/features/common/workspace/data/mappers/mapWorkspace';
import {
  ITeamMemberApiService,
  ITeamMemberEntityUI,
  ITeamMemberStateRepository,
} from '@/features/settings';

@injectable()
export default class TeamMemberStateRepository implements ITeamMemberStateRepository {
  @inject(TEAM_MEMBER_TYPES.TeamMemberApiService) private api: ITeamMemberApiService;

  inviteTeamMembers(teamMembers: ITeamMemberEntityUI[]): Promise<IWorkspaceEntity> {
    return firstValueFrom(
      this.api
        .inviteTeamMembers(teamMembers)
        .pipe(map((workspaceDc) => mapWorkspaceDcToEntity(workspaceDc))),
    );
  }

  deleteTeamMembers(
    list: {
      email: string;
      reassignTo: string;
    }[],
  ): Promise<boolean> {
    return firstValueFrom(
      this.api.deleteTeamMembers(
        list.map((item) => ({ email: item.email, reassign_to: item.reassignTo })),
      ),
    );
  }

  async resendInvitation(data: {
    email: string;
    role: string;
  }): Promise<IWorkspaceEntity> {
    return firstValueFrom(
      this.api.resendInvitation(data).pipe(map(mapWorkspaceDcToEntity)),
    );
  }
}
