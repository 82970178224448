import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Popover } from '@mui/material';
import clsx from 'clsx';

import { ActionDisableGuard } from '@/features/common/permissions';

import { MenuItem } from '@/components';
import { ArrowDownIcon } from '@/components/Icons';

import usePermissionsButtonViewModel from './PermissionsButtonViewModel';

import styles from './styles.module.scss';

type Props = {
  permissionsPopoverAnchor: HTMLButtonElement | null;
  onPermissionsButtonClick: React.MouseEventHandler<HTMLButtonElement>;
  onPermissionsPopoverClose(): void;
};

const PermissionsButton: React.FC<Props> = ({
  permissionsPopoverAnchor,
  onPermissionsPopoverClose,
  onPermissionsButtonClick,
}) => {
  const { t } = useTranslation('settings');
  const { canAccessAllContacts, allowAccessAllProspects, disallowAccessAllProspects } =
    usePermissionsButtonViewModel(onPermissionsPopoverClose);

  return (
    <>
      <ActionDisableGuard>
        <Button
          className={clsx(styles.PermissionsButton, {
            [styles.Opened]: !!permissionsPopoverAnchor,
          })}
          variant="outlined"
          color="info"
          size={'small'}
          endIcon={<ArrowDownIcon />}
          onClick={onPermissionsButtonClick}
        >
          {t('teamManagement.accessPermissionsButton')}
        </Button>
      </ActionDisableGuard>
      <Popover
        onClose={onPermissionsPopoverClose}
        anchorOrigin={{
          horizontal: -140,
          vertical: 'bottom',
        }}
        classes={{ paper: styles.PermissionsList }}
        open={!!permissionsPopoverAnchor}
        anchorEl={permissionsPopoverAnchor}
      >
        <Box onClick={disallowAccessAllProspects}>
          <MenuItem selected={!canAccessAllContacts}>
            {t('teamManagement.accessPermissionsButtonOwnText')}
          </MenuItem>
        </Box>
        <Box onClick={allowAccessAllProspects}>
          <MenuItem selected={canAccessAllContacts}>
            {t('teamManagement.accessPermissionsButtonAllText')}
          </MenuItem>
        </Box>
      </Popover>
    </>
  );
};

export default PermissionsButton;
