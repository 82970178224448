import { ReactNode } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { map } from 'rxjs';

import type { ICreditsDetailsEntity } from '@/features/billing/domain/entities/CreditsDetailsEntity';
import { useBillingSettingsUseCase } from '@/features/billing/ui/hooks/useBillingSettingsUseCase';

import { useObservableResult } from '@/utils/rx';

type UseCreditsOverviewViewModel = () => {
  isLoading: boolean;
  totalCreditsLeft: ReactNode;
  isOutOfCredits: boolean;
  bySources: {
    plan?: Nullable<{
      title: string;
      isOutOfCredits: boolean;
      creditsLeft: string;
      renewAt: string | null;
    }>;
  };
};

export const useCreditsOverviewVideModel: UseCreditsOverviewViewModel = () => {
  const { t } = useTranslation('billingSettings', {
    keyPrefix: 'subscriptionOverview.statistics',
  });

  const billingSettingsUseCase = useBillingSettingsUseCase();

  const formatTotalCreditsLeft = (
    data: ICreditsDetailsEntity['totalCreditsLeft'],
  ): ReactNode => {
    if (data === 'unlimited') return t('totalCreditsLeft.unlimited');

    return (
      <Trans
        t={t}
        key={'totalCredits.creditsLeft'}
        defaults="<b>{{count}}</b> Credits Left"
        values={{ count: data }}
        components={{ b: <b /> }}
      />
    );
  };

  const formatCreditsLeft = (params: { left: number; limit: number }): string => {
    return t('bySources.creditsLeftValue', params);
  };

  const formatDate = (date?: Date): string | null => {
    if (!date) return null;

    return t('bySources.renew', {
      date: date.toLocaleString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      }),
    });
  };

  const formatPlanCredits = (
    data: ICreditsDetailsEntity['bySources']['plan'],
  ): {
    title: string;
    isOutOfCredits: boolean;
    creditsLeft: string;
    renewAt: string | null;
  } | null => {
    if (!data) return null;

    const [planName = ''] = (data.plan ?? '').split('_');
    const capitalizedPlanName = `${planName.charAt(0).toUpperCase()}${planName.slice(1)}`;

    return {
      title: t('bySources.planTitle', { plan: capitalizedPlanName }),
      isOutOfCredits: data.left === 0,
      creditsLeft: formatCreditsLeft(data),
      renewAt: formatDate(data.renewAt),
    };
  };

  const creditsOverview = useObservableResult(() =>
    billingSettingsUseCase.getCreditsDetails().pipe(
      map(({ totalCreditsLeft, bySources }) => ({
        isOutOfCredits: totalCreditsLeft === 0,
        totalCreditsLeft: formatTotalCreditsLeft(totalCreditsLeft),
        bySources: {
          plan: formatPlanCredits(bySources.plan),
        },
      })),
    ),
  );

  return {
    isLoading: creditsOverview.isLoading,
    totalCreditsLeft: creditsOverview.data?.totalCreditsLeft ?? '',
    isOutOfCredits: creditsOverview.data?.isOutOfCredits ?? false,
    bySources: creditsOverview.data?.bySources ?? {},
  };
};
