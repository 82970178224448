import { useTranslation } from 'react-i18next';

import { IReciept } from '../../../domain/types';
import { IRecieptUI } from '../../types';

import { useFormatCurrency } from './useFormatCurrency';

type UsePromotion = ({
  promotion,
}: {
  promotion: IReciept['promotion'] | undefined;
}) => IRecieptUI['promotion'] | undefined;

export const usePromotion: UsePromotion = ({ promotion }) => {
  const { t } = useTranslation('paymentDetails', { keyPrefix: 'summary' });
  const format = useFormatCurrency();

  if (!promotion) return undefined;

  return {
    name: t('promotion', {
      name: promotion.name,
      amount: format(promotion.amount),
      percent: Number.isInteger(promotion.percent)
        ? promotion.percent
        : promotion.percent.toFixed(1),
    }),
  };
};
