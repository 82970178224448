import { createContext, FC, PropsWithChildren, useCallback } from 'react';

import { useInjection } from '@/ioc';
import { PERMISSIONS_TYPES } from '@/ioc/types';

import { SplashScreen } from '@/components';

import { useObservableResult } from '@/utils/rx';

import type { IPermissionsUseCase } from '../domain/PermissionsUseCase';
import type { Permission } from '../domain/types/Permission';

export type PermissionsContext = {
  isSuperAdmin: boolean;
  hasPermissions(permissions: Permission[]): boolean;
};

export const PermissionsContext = createContext<PermissionsContext>({
  isSuperAdmin: false,
  hasPermissions: () => false,
});

export const PermissionsProvider: FC<PropsWithChildren> = ({ children }) => {
  const permissionsUseCase = useInjection<IPermissionsUseCase>(
    PERMISSIONS_TYPES.PermissionsUseCase,
  );
  const { data: permissions, isLoading } = useObservableResult(
    () => permissionsUseCase.getAccessablePermissions(),
    { deps: [], defaultData: {} as Record<Permission, boolean> },
  );
  const { data: isSuperAdmin } = useObservableResult(
    () => permissionsUseCase.isSuperAdmin(),
    {
      deps: [],
      defaultData: false,
    },
  );

  const hasPermissions = useCallback(
    (permissionsToCheck: Permission[]) => {
      return permissionsToCheck.every((permission) => permissions[permission]);
    },
    [permissions],
  );

  if (isLoading) {
    return <SplashScreen />;
  }

  return (
    <PermissionsContext.Provider
      value={{
        isSuperAdmin,
        hasPermissions,
      }}
    >
      {children}
    </PermissionsContext.Provider>
  );
};
