import { inject, injectable } from 'inversify';
import { filter, iif, map, Observable, of, switchMap } from 'rxjs';

import { WORKSPACE_TYPES } from '@/ioc/types';

import { getPlanTypeFromSubscription } from '@/features/common/billing';
import {
  getBillingCycleFromSubscription,
  isPlanTypeUnlimited,
} from '@/features/common/billing/domain';
import type {
  ICreditsEntity,
  ISubscriptionUseCase,
  IWorkspaceEntity,
  IWorkspaceUseCase,
} from '@/features/common/workspace';

import type { IBillingSettingsUseCase } from './abstractions/IBillingSettingsUseCase';
import type { ICreditsDetailsEntity } from './entities/CreditsDetailsEntity';
import type { IPlanDetailsEntity } from './entities/PlanDetailsEntity';

@injectable()
export class BillingSettingsUseCase implements IBillingSettingsUseCase {
  @inject(WORKSPACE_TYPES.WorkspaceUseCase)
  private workspaceUseCase: IWorkspaceUseCase;

  @inject(WORKSPACE_TYPES.SubscriptionUseCase)
  private subscriptionUseCase: ISubscriptionUseCase;

  private getExpirationDate(params: {
    workspace: IWorkspaceEntity;
    credits?: ICreditsEntity;
  }): Date | undefined {
    const { workspace, credits } = params;

    if (workspace.subscription.expirationDate) {
      return new Date(workspace.subscription.expirationDate * 1000);
    }

    return credits?.expiresAt ? new Date(credits.expiresAt * 1000) : undefined;
  }

  private getCreditsLeft(credits?: ICreditsEntity): number {
    return credits ? Math.max(credits.limit - credits.used, 0) : 0;
  }

  public getCurrnetPlanDetails(): Observable<IPlanDetailsEntity> {
    return this.workspaceUseCase.getCurrentWorkspace().pipe(
      filter((workspace) => !!workspace),
      map((workspace) => {
        const credits = workspace.subscription.credits.find(
          (credit) => credit.creditType === 'full',
        );
        const planType = getPlanTypeFromSubscription(workspace.subscription);
        const isUnlimited = isPlanTypeUnlimited(planType);

        return {
          currentPlan: workspace.subscription.plan,
          users: Math.max(
            workspace.approvedMembersCount,
            workspace.subscription.paidMembersCount ?? 1,
          ),
          planCredits: isUnlimited ? 'unlimited' : credits?.limit || 0,
          billingCycle: getBillingCycleFromSubscription(workspace.subscription),
          creditsRenewOn: this.getExpirationDate({ workspace, credits }),
        } satisfies IPlanDetailsEntity;
      }),
    );
  }

  public getCreditsDetails(): Observable<ICreditsDetailsEntity> {
    return this.subscriptionUseCase
      .getIsUnlimitedPlan()
      .pipe(
        switchMap((isUnlimited) =>
          iif(
            () => isUnlimited,
            this.unlimitedCreditsDetails(),
            this.creditBasedCreditsDetails(),
          ),
        ),
      );
  }

  private unlimitedCreditsDetails(): Observable<ICreditsDetailsEntity> {
    return of({
      totalCreditsLeft: 'unlimited',
      bySources: {},
    });
  }

  private getPlanCreditsDetails(
    workspace: IWorkspaceEntity,
  ): ValuesOf<ICreditsDetailsEntity['bySources']> {
    const fullCredits = workspace.subscription.credits.find(
      (credit) => credit.creditType === 'full',
    );

    if (!fullCredits) return undefined;

    return {
      limit: fullCredits.limit,
      left: this.getCreditsLeft(fullCredits),
      renewAt: this.getExpirationDate({ workspace, credits: fullCredits }),
      plan: workspace.subscription.plan,
    };
  }

  private creditBasedCreditsDetails(): Observable<ICreditsDetailsEntity> {
    return this.workspaceUseCase.getCurrentWorkspace().pipe(
      filter((workspace) => !!workspace),
      map((workspace) => {
        const credits = workspace.subscription.credits.find(
          (credit) => credit.creditType === 'full',
        );

        if (!credits) {
          return {
            totalCreditsLeft: 0,
            bySources: {},
          } satisfies ICreditsDetailsEntity;
        }

        return {
          totalCreditsLeft: this.getCreditsLeft(credits),
          bySources: {
            plan: this.getPlanCreditsDetails(workspace),
          },
        } satisfies ICreditsDetailsEntity;
      }),
    );
  }
}
