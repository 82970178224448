import { useState } from 'react';

type UseSubscriptionOverviewViewModel = () => {
  isCollapsed: boolean;
  toggleCollapsed: () => void;
};

export const useSubscriptionOverviewViewModel: UseSubscriptionOverviewViewModel = () => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  const toggleCollapsed = (): void => {
    setIsCollapsed((isCollapsed) => !isCollapsed);
  };

  return {
    isCollapsed,
    toggleCollapsed,
  };
};
