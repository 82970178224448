import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { map } from 'rxjs';

import { ROUTES } from '@/router/routes';

import { useSubscriptionUseCase } from '@/features/common/workspace';

import { useObservableResult } from '@/utils/rx';

import { CallToActionBase, CallToActionBaseProps } from './CallToActionBase';

type Props = CallToActionBaseProps;

export const GetCredits: FC<Props> = ({ onClick, ...props }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const subscriptionUseCase = useSubscriptionUseCase();

  const { data: params } = useObservableResult(
    () =>
      subscriptionUseCase
        .getIsFreePlan()
        .pipe(
          map((isFreePlan) => `?source=${isFreePlan ? 'get-credits' : 'upgrade-plan'}`),
        ),
    { defaultData: '' },
  );

  const handleClick = (): void => {
    navigate(`${ROUTES.BILLING.PLANS}${params}`);
    onClick?.();
  };

  return (
    <CallToActionBase
      onClick={handleClick}
      {...props}
      text={t('callToActions.getCredits')}
    />
  );
};
