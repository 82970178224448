import { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Stack, Typography } from '@mui/material';

import { ActionDisableGuard } from '@/features/common/permissions';
import { useIsOnline } from '@/features/system/OnlineTracker';

import { Card } from '../Card';

import { useBillingDetailsViewModel } from './BillingDetailsViewModel';
import { EditBillingDetailsDialog } from './components';

import styles from './styles.module.scss';

export const BillingDetailsCard: FC = () => {
  const { t } = useTranslation('settings', {
    keyPrefix: 'subscriptions.billingDetails',
  });

  const viewModel = useBillingDetailsViewModel();
  const isOnline = useIsOnline();

  if (!viewModel.isVisible) {
    return null;
  }

  const {
    billingDetails,
    isEditDialogOpen,
    onEditDialogClose,
    onEditDialogOpen,
    onBillingDetailsUpdate,
  } = viewModel;

  return (
    <Fragment>
      <Card>
        <Box className={styles.details}>
          <ActionDisableGuard>
            <Button
              variant="outlined"
              className={styles.detailsAction}
              size="small"
              color="info"
              disabled={!isOnline}
              onClick={onEditDialogOpen}
            >
              {t('edit')}
            </Button>
          </ActionDisableGuard>
          <Typography variant="h1">{t('title')}</Typography>
          <Box className={styles.detailsContent}>
            <Typography className={styles.name}>{billingDetails.name}</Typography>
            {billingDetails.email ? (
              <Typography variant="inherit">{billingDetails.email}</Typography>
            ) : null}
            <Stack direction="column">
              <Typography variant="inherit">{billingDetails.address}</Typography>
              <Typography variant="inherit">
                {billingDetails.city}
                {billingDetails.state ? ` ${billingDetails.state}` : ''},{' '}
                {billingDetails.country} {billingDetails.postalCode}
              </Typography>
            </Stack>
            {billingDetails.phone ? (
              <Typography variant="inherit">{billingDetails.phone}</Typography>
            ) : null}
          </Box>
        </Box>
      </Card>
      <EditBillingDetailsDialog
        billingDetails={billingDetails}
        onSubmit={onBillingDetailsUpdate}
        isOpened={isEditDialogOpen}
        onClose={onEditDialogClose}
      />
    </Fragment>
  );
};
