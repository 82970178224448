import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { CallToAction } from '@/features/callToAction';
import { ActionDisableGuard } from '@/features/common/permissions';
import { PlanCardContentBase } from '@/features/dashboard/ui/PlanCard/components/PlanCardContentBase';

type PlanCardFreePlanProps = {
  title?: string;
};

export const PlanCardFreePlan: FC<PlanCardFreePlanProps> = ({ title }) => {
  const { t } = useTranslation(['dashboard', 'common']);

  return (
    <PlanCardContentBase title={title || t('freePlan')}>
      <ActionDisableGuard>
        <CallToAction
          show={['getCredits', 'getExtension', 'revealContact']}
          variant="outlined"
          size="small"
        />
      </ActionDisableGuard>
    </PlanCardContentBase>
  );
};
