import { FirebaseError } from 'firebase/app';
import {
  ActionCodeInfo,
  AuthProvider,
  GoogleAuthProvider,
  OAuthProvider,
  User,
  UserCredential,
} from 'firebase/auth';
import { inject, injectable } from 'inversify';
import {
  distinctUntilChanged,
  filter,
  firstValueFrom,
  map,
  Observable,
  share,
  switchMap,
} from 'rxjs';

import { container } from '@/ioc/container';
import { AUTH_TYPES } from '@/ioc/types';

import { Gate } from '@/utils/gate';
import { WorkEmailValidationSchema } from '@/utils/validation';

import {
  EmailSendRateLimitError,
  InvalidWorkEmailError,
  UserAlreadyExistsError,
  UserNotFoundError,
} from '../domain';
import { AuthStatus } from '../domain/types';

import { IAuthApiService } from './network/AuthApiService';
import { mapFirebaseErrorToAuthError } from './mappers';
import { FirebaseService } from './network';

export interface IAuthRepository {
  getUser(): Observable<User | null | undefined>;
  getUserCountry(): Observable<string>;
  getAccessToken(): Observable<string>;
  getAuthStatus(): Observable<AuthStatus>;
  applyActionCode(actionCode: string): Promise<void>;
  checkActionCode(actionCode: string): Promise<ActionCodeInfo>;
  checkUserExists(email: string): Promise<boolean>;
  sendPasswordResetEmail(email: string): Promise<boolean>;
  sendVerificationEmail(email: string): Promise<boolean>;
  resetPassword(oobCode: string, newPassword: string): Promise<void>;
  updatePassword(newPassword: string, currentPassword?: string): Promise<void>;
  signInWithEmailAndPassword(email: string, password: string): Promise<string>;
  signInWithGoogle(): Promise<string>;
  signInWithMicrosoft(): Promise<string>;
  signInWithCustomToken(customToken: string): Promise<UserCredential>;
  signUpWithEmailAndPassword(email: string, password: string): Promise<string>;
  signUpWithGoogle(): Promise<string>;
  signUpWithMicrosoft(): Promise<string>;
  signOut(): Promise<void>;
  getMicrosoftAuthProvider(): OAuthProvider;
  getGoogleAuthProvider(): GoogleAuthProvider;
  reloadUser(): Promise<void>;
}

@injectable()
export class AuthRepository implements IAuthRepository {
  @inject(AUTH_TYPES.FirebaseService)
  private readonly firebaseService: FirebaseService;

  private authReactivityLocker = new Gate();

  // cicrcular dependency
  private get authApiService(): IAuthApiService {
    return container.get<IAuthApiService>(AUTH_TYPES.AuthApiService);
  }

  getUser(): Observable<Nullable<User>> {
    return this.firebaseService.user.pipe(this.authReactivityLocker.pass, share());
  }

  getUserCountry(): Observable<string> {
    return this.authApiService.getUserInfoByIp().pipe(map((data) => data?.country_code));
  }

  getAuthStatus = (): Observable<AuthStatus> => {
    return this.getUser().pipe(
      map((user) => {
        if (user === undefined) {
          return AuthStatus.Initialisation;
        }

        if (user === null) {
          return AuthStatus.Unauthorized;
        }

        return AuthStatus.Authorized;
      }),
      distinctUntilChanged(),
    );
  };

  getAccessToken = (): Observable<string> => {
    return this.getAuthStatus().pipe(
      filter((status) => status !== AuthStatus.Initialisation),
      switchMap(() => this.getUser()),
      map((user) => (Reflect.get(user ?? {}, 'accessToken') as string) ?? ''),
      distinctUntilChanged(),
    );
  };

  async sendPasswordResetEmail(email: string): Promise<boolean> {
    try {
      const response = await firstValueFrom(
        this.authApiService.sendResetPasswordLink(email),
      );

      return response;
    } catch (error) {
      if (error.statusCode === 429) {
        throw new EmailSendRateLimitError();
      }
      if (error.statusCode === 400) {
        throw new UserNotFoundError();
      }

      throw error;
    }
  }

  applyActionCode(actionCode: string): Promise<void> {
    return this.firebaseService.applyActionCode(actionCode);
  }

  checkActionCode(actionCode: string): Promise<ActionCodeInfo> {
    return this.firebaseService.checkActionCode(actionCode);
  }

  checkUserExists(email: string): Promise<boolean> {
    return this.firebaseService.checkUserExists(email);
  }

  resetPassword(oobCode: string, newPassword: string): Promise<void> {
    return this.firebaseService.confirmPasswordReset(oobCode, newPassword);
  }

  async updatePassword(newPassword: string, currentPassword?: string): Promise<void> {
    try {
      if (currentPassword) {
        await this.firebaseService.reauthenticateWithPassword(currentPassword);
      }
      await this.firebaseService.updatePassword(newPassword);
    } catch (error) {
      if (error instanceof FirebaseError) {
        throw mapFirebaseErrorToAuthError(error);
      }
      throw error;
    }
  }

  async signInWithEmailAndPassword(email: string, password: string): Promise<string> {
    try {
      const userCredential = await this.firebaseService.signInWithEmailAndPassword(
        email,
        password,
      );
      const idToken = await userCredential.user.getIdToken();

      return idToken;
    } catch (error) {
      if (error instanceof FirebaseError) {
        throw mapFirebaseErrorToAuthError(error);
      }

      throw error;
    }
  }

  signInWithGoogle(): Promise<string> {
    return this.signInWithProvider(this.firebaseService.googleAuthProvider);
  }

  signInWithMicrosoft(): Promise<string> {
    return this.signInWithProvider(this.firebaseService.microsoftAuthProvider);
  }

  signInWithCustomToken(customToken: string): Promise<UserCredential> {
    try {
      return this.firebaseService.signInWithCustomToken(customToken);
    } catch (error) {
      if (error instanceof FirebaseError) {
        throw mapFirebaseErrorToAuthError(error);
      }
      throw error;
    }
  }

  async signUpWithEmailAndPassword(email: string, password: string): Promise<string> {
    try {
      await WorkEmailValidationSchema.validate(email).catch(() => {
        throw new InvalidWorkEmailError();
      });

      const userCredetial = await this.firebaseService.createUserWithEmailAndPassword(
        email,
        password,
      );
      const idToken = await userCredetial.user.getIdToken();

      return idToken;
    } catch (error) {
      if (error instanceof FirebaseError) {
        throw mapFirebaseErrorToAuthError(error);
      }

      throw error;
    }
  }

  signUpWithGoogle(): Promise<string> {
    return this.signUpWithProvider(this.firebaseService.googleAuthProvider);
  }

  signUpWithMicrosoft(): Promise<string> {
    return this.signUpWithProvider(this.firebaseService.microsoftAuthProvider);
  }

  signOut(): Promise<void> {
    return this.firebaseService.signOut();
  }

  async sendVerificationEmail(email: string): Promise<boolean> {
    try {
      const response = await firstValueFrom(
        this.authApiService.sendEmailVerificationLink(email),
      );

      return response;
    } catch (error) {
      if (error.statusCode === 429) {
        throw new EmailSendRateLimitError();
      }
      if (error.statusCode === 400) {
        throw new UserNotFoundError();
      }

      throw error;
    }
  }

  reloadUser(): Promise<void> {
    return this.firebaseService.reloadUser();
  }

  getGoogleAuthProvider(): GoogleAuthProvider {
    return this.firebaseService.googleAuthProvider;
  }

  getMicrosoftAuthProvider(): OAuthProvider {
    return this.firebaseService.microsoftAuthProvider;
  }

  private async signInWithProvider(provider: AuthProvider): Promise<string> {
    try {
      this.authReactivityLocker.lock();
      const userCredential = await this.firebaseService.signInWithPopup(provider);
      await WorkEmailValidationSchema.validate(userCredential.user.email).catch(() => {
        return this.firebaseService.signOut();
      });
      const additionalUserInfo =
        this.firebaseService.getAdditionalUserInfo(userCredential);

      if (additionalUserInfo?.isNewUser) {
        await Promise.all([
          this.firebaseService.deleteUser(),
          this.firebaseService.signOut(),
        ]);
        throw new UserNotFoundError();
      }

      const idToken = await userCredential.user.getIdToken();

      return idToken;
    } catch (error) {
      if (error instanceof FirebaseError) {
        throw mapFirebaseErrorToAuthError(error);
      }

      throw error;
    } finally {
      this.authReactivityLocker.unlock();
    }
  }

  private async signUpWithProvider(provider: AuthProvider): Promise<string> {
    try {
      this.authReactivityLocker.lock();

      const userCredential = await this.firebaseService.signInWithPopup(provider);
      const additionalUserInfo =
        this.firebaseService.getAdditionalUserInfo(userCredential);

      try {
        await WorkEmailValidationSchema.validate(userCredential.user.email);
      } catch (validationError) {
        if (additionalUserInfo?.isNewUser) {
          await this.firebaseService.deleteUser();
        }
        throw new InvalidWorkEmailError();
      }

      if (additionalUserInfo?.isNewUser) {
        return await userCredential.user.getIdToken();
      } else {
        throw new UserAlreadyExistsError();
      }
    } catch (error) {
      if (error instanceof FirebaseError) {
        throw mapFirebaseErrorToAuthError(error);
      }
      await this.firebaseService.signOut();

      throw error;
    } finally {
      this.authReactivityLocker.unlock();
    }
  }
}
