import type { IStripePromotionCodeEntity } from '../../domain';
import type { IStripePromotionCodeDC } from '../dataContracts';

import { mapStripeCouponDcToEnity } from './stripeCouponMapper';

export function mapStripePromotionCodeDcToEntity(
  dc: IStripePromotionCodeDC,
): IStripePromotionCodeEntity {
  return {
    id: dc.id,
    active: dc.active,
    code: dc.code,
    coupon: mapStripeCouponDcToEnity(dc.coupon),
    expiresAt: dc.expires_at,
    maxRedemptions: dc.max_redemptions,
    restrictions: dc.restrictions
      ? {
          firstTimeTransaction: dc.restrictions.first_time_transaction,
          minimumAmount: dc.restrictions.minimum_amount,
          minimumAmountCurrency: dc.restrictions.minimum_amount_currency,
        }
      : null,
  };
}
