import { FC, PropsWithChildren, ReactNode } from 'react';

import { Permission } from '../../domain/types/Permission';
import { usePermissions } from '../hooks/usePermissions';

export type Props = {
  permission: Permission | Permission[];
  fallback?: ReactNode;
};

export const HasPermission: FC<PropsWithChildren<Props>> = ({
  children,
  permission,
  fallback = null,
}) => {
  const { hasPermissions } = usePermissions();
  const canAccessChildren = Array.isArray(permission)
    ? hasPermissions(permission)
    : hasPermissions([permission]);
  return canAccessChildren ? <>{children}</> : <>{fallback}</>;
};
