import { AxiosResponse } from 'axios';
import { inject, injectable } from 'inversify';
import { map, Observable } from 'rxjs';

import { NETWORK_TYPES } from '@/ioc/types';

import { IWorkspaceDC } from '@/features/common/workspace';
import {
  IDeleteMemberDataDC,
  ITeamMemberApiService,
  ITeamMemberEntityUI,
} from '@/features/settings';
import { IHttpClient } from '@/features/system/network';

@injectable()
export default class TeamMemberApiService implements ITeamMemberApiService {
  @inject(NETWORK_TYPES.HttpClient) private httpClient: IHttpClient;

  inviteTeamMembers(teamMembers: ITeamMemberEntityUI[]): Observable<IWorkspaceDC> {
    return this.httpClient
      .put('/workspaces/members', teamMembers)
      .pipe(map((response: AxiosResponse<IWorkspaceDC>) => response.data));
  }

  deleteTeamMembers(list: IDeleteMemberDataDC[]): Observable<boolean> {
    return this.httpClient
      .delete('/workspaces/members', list)
      .pipe(map((response: AxiosResponse<boolean>) => response.data));
  }

  resendInvitation(data: { email: string; role: string }): Observable<IWorkspaceDC> {
    return this.httpClient
      .put('/workspaces/members?resend_invitation=true', data)
      .pipe(map((response: AxiosResponse<IWorkspaceDC>) => response.data));
  }
}
