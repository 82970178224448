import { FC, PropsWithChildren } from 'react';
import { Box, Typography } from '@mui/material';
import { clsx } from 'clsx';

import { CreditsLeftCounter } from '@/features/creditsCounter';

import dashboardStyles from '../../styles.module.scss';
import styles from '../styles.module.scss';

type PlanCardContentBaseProps = {
  title: string;
  hideCreditsData?: boolean;
};

export const PlanCardContentBase: FC<PropsWithChildren<PlanCardContentBaseProps>> = ({
  children,
  title,
  hideCreditsData = false,
}) => {
  return (
    <Box className={clsx(dashboardStyles.card, styles.cardRoot)}>
      <Box>
        <Box className={styles.cardTitle}>
          <Typography variant="h1">{title}</Typography>
        </Box>

        {hideCreditsData ? null : <CreditsLeftCounter size="large" />}
      </Box>

      {children}
    </Box>
  );
};
