import { useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { firstValueFrom } from 'rxjs';

import {
  BillingCycle,
  getPlanTypeFromSubscription,
  getSubscriptionPlanFrom,
  IStripePromotionCodeEntity,
  PlanType,
  useBillingUseCase,
} from '@/features/common/billing';
import { DiscountAnnual } from '@/features/common/billing/domain';
import { IWorkspaceSubscriptionEntity } from '@/features/common/workspace';

import { PaymentFormValues } from '../contexts';
import { IRecieptUI } from '../types';

import { useReciept } from '.';

type UsePaymentSummary = (params: {
  currentSubscription: IWorkspaceSubscriptionEntity;
}) => {
  showBillingCycleSwitcher: boolean;
  annualDiscountPercent: string;
  billingCycle: BillingCycle;
  planType: PlanType;
  reciept: IRecieptUI;
  onPromotionCodeApply: (code: string) => Promise<void>;
  onPromotionCodeRemove: () => void;
  promotionCode?: IStripePromotionCodeEntity;
  promotionsAvailable: boolean;
};

export const usePaymentSummary: UsePaymentSummary = ({ currentSubscription }) => {
  const billingUseCase = useBillingUseCase();

  const [promotionCode, setPromotionCode] = useState<
    IStripePromotionCodeEntity | undefined
  >();

  const { control } = useFormContext<PaymentFormValues>();

  const [planType, billingCycle, seats] = useWatch({
    control,
    name: ['planType', 'billingCycle', 'seats'],
  });

  const promotionsAvailable =
    getPlanTypeFromSubscription(currentSubscription) === PlanType.Free;

  const reciept = useReciept({
    planType,
    billingCycle,
    promotionCode,
    seats,
  });

  function onPromotionCodeRemove(): void {
    setPromotionCode(undefined);
  }

  async function onPromotionCodeApply(code: string): Promise<void> {
    const promotionCode = await firstValueFrom(
      billingUseCase.getPromoCodeInfo({
        code: code,
        plan: getSubscriptionPlanFrom({
          planType,
          billingCycle,
        }),
      }),
    );
    setPromotionCode(promotionCode);
  }

  return {
    annualDiscountPercent: (DiscountAnnual.rate * 100).toFixed(0),
    showBillingCycleSwitcher: false,
    billingCycle,
    planType,
    reciept,
    onPromotionCodeRemove,
    onPromotionCodeApply,
    promotionCode,
    promotionsAvailable,
  };
};
