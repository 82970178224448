import { inject, injectable } from 'inversify';
import { firstValueFrom, map, Observable, of, switchMap } from 'rxjs';
import { filter } from 'rxjs';

import { ACCOUNT_TYPES, WORKSPACE_TYPES } from '@/ioc/types';

import type { ITeamMemberEntity } from '@/features/settings';

import type { IAccountDao } from '../../account';
import { PromotionExpiredError } from '../../billing/domain/errors/PromotionExpiredError';
import { SubscriptionPlan } from '../domain';
import type { IWorkspaceEntity } from '../domain/entities/WorkspaceEntity';
import type { IWorkspaceSubscriptionEntity } from '../domain/entities/WorkspaceSubscriptionEntity';

import type { IWorkspaceRepository } from './abstractions/WorksapceRepository';
import type { IWorkspaceApiService } from './abstractions/WorkspaceApiService';
import type { IWorkspaceDao } from './db/dao/WorkspaceDao';
import { mapWorkspaceDcToEntity, mapWorkspaceEntityToDc } from './mappers/mapWorkspace';

const PROMO_EXPIRED_RESPONSE = 'This promotion code has expired';

@injectable()
export default class WorkspaceRepository implements IWorkspaceRepository {
  @inject(WORKSPACE_TYPES.WorkspaceDao)
  private workspaceDao: IWorkspaceDao;

  @inject(ACCOUNT_TYPES.AccountDao)
  private accountDao: IAccountDao;

  @inject(WORKSPACE_TYPES.WorkspaceApiService)
  private apiService: IWorkspaceApiService;

  async updateWorkspace(workspaceEntity: IWorkspaceEntity): Promise<IWorkspaceEntity> {
    const result = await this.workspaceDao.upsert(
      mapWorkspaceEntityToDc(workspaceEntity),
    );

    return mapWorkspaceDcToEntity(result);
  }

  async createSubscription(params: {
    plan: SubscriptionPlan;
    quantity?: number;
    promoCode?: string;
  }): Promise<{
    status:
      | 'incomplete'
      | 'incomplete_expired'
      | 'trialing'
      | 'active'
      | 'past_due'
      | 'canceled'
      | 'unpaid'
      | 'paused';
    secret?: string;
  }> {
    try {
      const subsription = await this.apiService.createSubscription({
        label: params.plan,
        quantity: params.quantity,
        promo_code: params.promoCode,
      });

      return {
        status: subsription.status,
        secret: subsription.latest_invoice.payment_intent?.client_secret,
      };
    } catch (e) {
      if (e.response?.data?.includes(PROMO_EXPIRED_RESPONSE)) {
        throw new PromotionExpiredError();
      }

      throw e;
    }
  }

  async updateSubscription(params: {
    plan?: SubscriptionPlan;
    isCanceled?: boolean;
    billingDetailsFilled?: boolean;
    quantity?: number;
    promoCode?: string;
  }): Promise<void> {
    const workspace = await firstValueFrom(this.getCurrentWorkspace());

    if (!workspace) {
      throw new Error('Workspace not found');
    }

    await this.apiService.updateSubscription({
      workspaceId: workspace.uuid,
      is_canceled: params.isCanceled,
      plan: params.plan,
      billing_details_filled: params.billingDetailsFilled,
      quantity: params.quantity,
      promo_code: params.promoCode,
    });
  }

  getWorkspaces(): Observable<IWorkspaceEntity[]> {
    return this.workspaceDao.findAll().pipe(
      map((workspaces) => {
        return workspaces.map(mapWorkspaceDcToEntity);
      }),
    );
  }

  switchWorkspace(req: { uuid: string; action: 'accept' | 'reject' }): Promise<boolean> {
    return firstValueFrom(this.apiService.switchWorkspace(req));
  }

  getCurrentWorkspace(): Observable<IWorkspaceEntity | null> {
    return this.accountDao.getCurrent().pipe(
      map((account) => account?.personal_workspace_id),
      switchMap((id: string) => {
        if (!id) {
          return of(null);
        }

        return this.workspaceDao
          .findById(id)
          .pipe(
            map((workspace) => (workspace ? mapWorkspaceDcToEntity(workspace) : null)),
          );
      }),
    );
  }

  getCurrentWorkspaceOwner(): Observable<ITeamMemberEntity> {
    return this.getCurrentWorkspace().pipe(
      filter((workspace): workspace is IWorkspaceEntity => !!workspace),
      map((workspace) => {
        return workspace.members.find(
          (member) => member.role === 'owner',
        ) as ITeamMemberEntity;
      }),
    );
  }

  getCurrentWorkspaceSubscription(): Observable<IWorkspaceSubscriptionEntity> {
    return this.getCurrentWorkspace().pipe(
      filter((workspace) => !!workspace?.subscription),
      map((workspace: IWorkspaceEntity) => workspace.subscription),
    );
  }
}
