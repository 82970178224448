import { FC, Fragment, PropsWithChildren, ReactNode, useMemo } from 'react';

import { useAccount } from '@/features/common/account';
import { useGetBillingDetails, useGetPaymentMethod } from '@/features/common/billing';
import {
  useCurrentWorkspace,
  useWorkspaceSubscription,
} from '@/features/common/workspace';

import { useGetInvoiceData } from '../../hooks/useGetInvoiceData';

import { PaymentDetailsContext, type PaymentDetailsContextType } from './Context';

type NullableFields<T extends object> = {
  [K in keyof T]: Nullable<T[K]>;
};

function assertAllDataLoaded(
  context: NullableFields<PaymentDetailsContextType>,
): asserts context is PaymentDetailsContextType {
  if (!context.account) {
    throw new Error('Account data not loaded');
  }
  if (!context.currentSubscription) {
    throw new Error('Subscription data not loaded');
  }

  if (!context.currentWorkspace) {
    throw new Error('Workspace data not loaded');
  }
}

/*
Purpose of this provider is to provide all necessary data
for payment details before rendering feature
*/
export const PaymentDetailsProvider: FC<
  PropsWithChildren<{
    fallback?: ReactNode;
  }>
> = ({ children, fallback = null }) => {
  const { account, isLoading: isAccountLoading } = useAccount();

  const { data: currentSubscription, isLoading: isSubscriptionLoading } =
    useWorkspaceSubscription();

  const { data: currentWorkspace, isLoading: isWorkspaceLoading } = useCurrentWorkspace();

  const { data: paymentMethod, isLoading: isPaymentMethodLoading } =
    useGetPaymentMethod();

  const { data: billingDetails, isLoading: isBillingDetailsLoading } =
    useGetBillingDetails();

  const { data: invoiceData, isLoading: isInvoiceDataLoading } = useGetInvoiceData();

  const isLoading =
    isAccountLoading ||
    isSubscriptionLoading ||
    isWorkspaceLoading ||
    isPaymentMethodLoading ||
    isBillingDetailsLoading ||
    isInvoiceDataLoading;

  const contextValue = useMemo<NullableFields<PaymentDetailsContextType>>(
    () => ({
      account,
      currentSubscription,
      currentWorkspace,
      paymentMethod,
      billingDetails,
      invoiceData,
    }),
    [
      account,
      currentSubscription,
      currentWorkspace,
      paymentMethod,
      billingDetails,
      invoiceData,
    ],
  );

  if (isLoading) {
    return <Fragment>{fallback}</Fragment>;
  }

  assertAllDataLoaded(contextValue);

  return (
    <PaymentDetailsContext.Provider value={contextValue}>
      {children}
    </PaymentDetailsContext.Provider>
  );
};
