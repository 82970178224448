import { useInjection } from '@/ioc/ioc.react';
import { PAYMENT_DETAILS_TYPES } from '@/ioc/types';

import { getSubscriptionPlanFrom } from '@/features/common/billing';
import { IPaymentDetailsUseCase } from '@/features/paymentDetails';

import { ObservableResult, useObservableResult } from '@/utils/rx';

import { IInvoiceDataEntity } from '../../domain';

import { useBillingCycleFromUrl, usePlanFromUrl } from '.';

export const useGetInvoiceData = (): ObservableResult<IInvoiceDataEntity> => {
  const paymentDetailsUseCase = useInjection<IPaymentDetailsUseCase>(
    PAYMENT_DETAILS_TYPES.PaymentDetailsUseCase,
  );

  const [planType] = usePlanFromUrl();
  const [billingCycle] = useBillingCycleFromUrl();

  const plan = getSubscriptionPlanFrom({ planType, billingCycle });

  return useObservableResult(() => paymentDetailsUseCase.getInvoiceData(plan), {
    deps: [plan],
  });
};
