import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Card, CardActions, CardContent, CardHeader } from '@mui/material';
import clsx from 'clsx';

import { ArrowUpIcon } from '@/components/Icons';

import { CreditsOverview } from './components/CreditsOverview';
import { PlanOverview } from './components/PlanOverview';
import { useSubscriptionOverviewViewModel } from './useSubscriptionOverviewViewModel';

import styles from './styles.module.scss';

export const SubscriptionOverview: FC = () => {
  const { t } = useTranslation('billingSettings', { keyPrefix: 'subscriptionOverview' });
  const { isCollapsed, toggleCollapsed } = useSubscriptionOverviewViewModel();

  return (
    <Card className={styles.card}>
      <CardHeader
        title={t('title')}
        classes={{
          title: styles.title,
          header: styles.header,
        }}
      />
      <CardContent className={styles.content}>
        <PlanOverview />
        <CreditsOverview isCollapsed={isCollapsed} />
      </CardContent>
      <CardActions className={styles.actions}>
        <Button
          className={styles.collapseButton}
          endIcon={
            <ArrowUpIcon
              className={clsx(styles.collapseButtonIcon, {
                [styles.collapsed]: isCollapsed,
              })}
            />
          }
          onClick={toggleCollapsed}
        >
          {isCollapsed ? t('actions.showCredits') : t('actions.hideCredits')}
        </Button>
      </CardActions>
    </Card>
  );
};
