import { inject, injectable } from 'inversify';
import { filter, firstValueFrom, map, Observable, switchMap } from 'rxjs';

import { ACCOUNT_TYPES, CONTACT_LIST_TYPES, PERMISSIONS_TYPES } from '@/ioc/types';

import { IAccountRepository } from '@/features/common/account';
import {
  ContactListSortSchema,
  IContactListEntity,
  IContactListRepository,
  IContactListUseCase,
} from '@/features/common/contactList';
import { IPermissionsRepository, Permission } from '@/features/common/permissions';

import { ContactListNotFoundError } from './errors';

@injectable()
export default class ContactListUseCase implements IContactListUseCase {
  @inject(CONTACT_LIST_TYPES.ContactListRepository)
  private readonly contactListRepository: IContactListRepository;

  @inject(ACCOUNT_TYPES.AccountRepository)
  private readonly accountRepository: IAccountRepository;

  @inject(PERMISSIONS_TYPES.PermissionsRepository)
  private readonly permissionsRepository: IPermissionsRepository;

  public getDefaultContactList(): Observable<IContactListEntity | null> {
    return this.contactListRepository.getDefaultContactList();
  }

  public getContactListByQuery(payload: {
    nameReg?: string;
    created_by?: string;
    sortSchema?: ContactListSortSchema;
  }): Observable<IContactListEntity[]> {
    return this.permissionsRepository
      .hasPermissions([Permission.CanManageContactLists])
      .pipe(
        switchMap((hasPermission) => {
          if (!hasPermission) {
            return this.accountRepository.getAccount().pipe(
              filter((account) => !!account),
              switchMap((account) => {
                return this.contactListRepository.getContactListByQuery({
                  ...payload,
                  created_by: account.uuid,
                });
              }),
            );
          }

          return this.contactListRepository.getContactListByQuery(payload);
        }),
      );
  }

  public getContactListById(id: string): Observable<IContactListEntity> {
    return this.contactListRepository.getContactListById(id).pipe(
      map((value) => {
        if (!value) throw new ContactListNotFoundError(id);

        return value;
      }),
    );
  }

  public async createContactList(payload: { name: string }): Promise<IContactListEntity> {
    const account = await firstValueFrom(this.accountRepository.getAccount());
    return this.contactListRepository.createContactList({
      ...payload,
      created_by: account?.uuid ?? '',
    });
  }

  public updateContactList(payload: {
    uuid: string;
    name?: string;
  }): Promise<IContactListEntity> {
    return this.contactListRepository.updateContactList(payload);
  }

  public deleteContactList(uuid: string): Promise<boolean> {
    return this.contactListRepository.deleteContactList(uuid);
  }
}
