import { inject, injectable } from 'inversify';
import {
  combineLatest,
  distinctUntilChanged,
  EMPTY,
  filter,
  firstValueFrom,
  map,
  Observable,
  switchMap,
} from 'rxjs';

import { EXTERNAL_ROUTES } from '@/router/routes';

import {
  ACCOUNT_TYPES,
  ANALYTICS_TYPES,
  AUTH_TYPES,
  EXTENSION_TYPES,
  LEADER_ELECTION_TYPES,
  WORKSPACE_TYPES,
} from '@/ioc/types';

import {
  ANALYTICS_EVENTS,
  IAnalyticsRepository,
  WalkthroughStep,
} from '@/features/system/analytics';
import { FbPixelEvent, fbPixelTrackEvent } from '@/features/system/FbPixel';
import { ILeaderElectionRepository } from '@/features/system/leaderElection';

import { isMobile } from '@/utils/isMobile';

import { IAccountEntity, IAccountRepository, UserRole } from '../../account';
import { AuthStatus, IAuthRepository } from '../../auth';
import { getPlanTypeFromSubscription, PlanType } from '../../billing';
import { IExtensionRepository } from '../../extension';
import { IWorkspaceEntity, IWorkspaceRepository } from '../../workspace';

import { IOnboardingUseCase } from './abstractions';
import { mapStepToAccountOnboardingField } from './mappers';
import { OnboardingStep } from './types';

@injectable()
export class OnboardingUseCase implements IOnboardingUseCase {
  @inject(ACCOUNT_TYPES.AccountRepository)
  private readonly accountRepository: IAccountRepository;

  @inject(WORKSPACE_TYPES.WorkspaceRepository)
  private readonly workspaceRepository: IWorkspaceRepository;

  @inject(EXTENSION_TYPES.ExtensionRepository)
  private readonly extensionRepository: IExtensionRepository;

  @inject(AUTH_TYPES.AuthRepository)
  private readonly authRepository: IAuthRepository;

  @inject(LEADER_ELECTION_TYPES.LeaderElectionRepository)
  private leaderElectionRepository: ILeaderElectionRepository;

  @inject(ANALYTICS_TYPES.AnalyticsRepository)
  private analyticsRepository: IAnalyticsRepository;

  private toStatus(status: boolean): 'completed' | 'incompleted' {
    return status ? 'completed' : 'incompleted';
  }

  private async completeStep(
    step: OnboardingStep,
    options?: { skipReplication?: boolean },
  ): Promise<void> {
    if (options?.skipReplication) {
      return this.accountRepository.completeOnboardingStep(
        mapStepToAccountOnboardingField(step),
      );
    }

    const account = await firstValueFrom(
      this.accountRepository.getAccount().pipe(filter((account) => !!account)),
    );

    if (!account || account.settings.onboarding[mapStepToAccountOnboardingField(step)])
      return;

    await this.accountRepository.updateSettings({
      onboarding: {
        ...account.settings.onboarding,
        [mapStepToAccountOnboardingField(step)]: true,
      },
    });
  }

  private listenerGuard(): Observable<boolean> {
    return combineLatest({
      isLeader: this.leaderElectionRepository.getIsLeaderSubject().asObservable(),
      authStatus: this.authRepository.getAuthStatus(),
    }).pipe(
      map(({ isLeader, authStatus }) => authStatus === AuthStatus.Authorized && isLeader),
      distinctUntilChanged(),
      filter((canListen) => canListen),
    );
  }

  private listenToExtensionInstallation(): Observable<void> {
    return this.isStepCompleted('installExtension').pipe(
      switchMap((isCompleted) => {
        if (isCompleted) return EMPTY;

        return this.extensionRepository.isExtensionInstalled().pipe(
          distinctUntilChanged(),
          filter((extensionInstalled) => !!extensionInstalled),
          switchMap(() => {
            this.analyticsRepository.trackEvent(
              ANALYTICS_EVENTS.USER_PRODUCT_WALKTHROUGH,
              {
                step: WalkthroughStep.installedExtension,
              },
            );
            fbPixelTrackEvent({ type: FbPixelEvent.SubmitApplication });
            return this.completeStep('installExtension');
          }),
          switchMap(() => EMPTY), // to complete stream
        );
      }),
    );
  }

  private listenToProspectRevealing(): Observable<void> {
    return this.isStepCompleted('revealContact').pipe(
      switchMap((isCompleted) => {
        if (isCompleted) return EMPTY;

        return this.accountRepository.getAccount().pipe(
          map((account) => (account?.totalCreditsUsed ?? 0) > 0),
          distinctUntilChanged(),
          filter((hasUsedCredits) => hasUsedCredits),
          switchMap(() => {
            this.analyticsRepository.trackEvent(
              ANALYTICS_EVENTS.USER_PRODUCT_WALKTHROUGH,
              {
                step: WalkthroughStep.revealedContact,
              },
            );
            return this.completeStep('revealContact');
          }),
          switchMap(() => EMPTY), // to complete stream
        );
      }),
    );
  }

  private listenForInviteTeamMember(): Observable<void> {
    return this.isStepCompleted('inviteTeamMember').pipe(
      switchMap((isCompleted) => {
        if (isCompleted) return EMPTY;

        return combineLatest({
          account: this.accountRepository.getAccount(),
          workspace: this.workspaceRepository.getCurrentWorkspace(),
        }).pipe(
          map(
            ({ account, workspace }) =>
              !!account &&
              !!workspace &&
              (workspace.members.some((member) => member.invitedBy === account.uuid) ||
                account.referrerCreditsEarned > 0),
          ),
          distinctUntilChanged(),
          filter((hasInvitedMembers) => hasInvitedMembers),
          switchMap(() => this.completeStep('inviteTeamMember')),
          switchMap(() => EMPTY), // to complete stream
        );
      }),
    );
  }

  public isStepCompleted(step: OnboardingStep): Observable<boolean> {
    return this.accountRepository.getAccount().pipe(
      map(
        (account) =>
          account?.settings.onboarding[mapStepToAccountOnboardingField(step)] ?? false,
      ),
      distinctUntilChanged(),
    );
  }

  public startListeners(): Observable<void> {
    return this.listenerGuard().pipe(
      switchMap(() =>
        combineLatest([
          this.listenToExtensionInstallation(),
          this.listenToProspectRevealing(),
          this.listenForInviteTeamMember(),
        ]),
      ),
      map(() => undefined),
    );
  }

  public getStatus(): Observable<'completed' | 'incompleted'> {
    return this.getStepList().pipe(
      map((steps) => {
        return steps.every((step) => step.status === 'completed')
          ? 'completed'
          : 'incompleted';
      }),
    );
  }

  public getStepList(): Observable<
    { step: OnboardingStep; status: 'completed' | 'incompleted' }[]
  > {
    return combineLatest({
      account: this.accountRepository.getAccount(),
      subscription: this.workspaceRepository.getCurrentWorkspaceSubscription(),
    }).pipe(
      filter(({ account, subscription }) => !!account && !!subscription),
      map(
        ({
          account,
          subscription,
        }: {
          account: IAccountEntity;
          subscription: IWorkspaceEntity['subscription'];
        }) => {
          const planType = getPlanTypeFromSubscription(subscription);
          const isManager = account.role === UserRole.Manager;
          const isMember = account.role === UserRole.Member;

          if (planType === PlanType.Free) {
            return [
              {
                step: 'installExtension',
                status: this.toStatus(account.settings.onboarding.isExtensionInstalled),
              },
              {
                step: 'revealContact',
                status: this.toStatus(account.settings.onboarding.isContactRevealed),
              },
              {
                step: 'inviteTeamMember',
                status: this.toStatus(account.settings.onboarding.isTeamMemberInvited),
              },
            ];
          }

          if (isManager) {
            return [
              {
                step: 'installExtension',
                status: this.toStatus(account.settings.onboarding.isExtensionInstalled),
              },
              {
                step: 'exportContact',
                status: this.toStatus(account.settings.onboarding.isContactExported),
              },
            ];
          }

          if (isMember) {
            return [
              {
                step: 'installExtension',
                status: this.toStatus(account.settings.onboarding.isExtensionInstalled),
              },
              {
                step: 'revealContact',
                status: this.toStatus(account.settings.onboarding.isContactRevealed),
              },
              {
                step: 'exportContact',
                status: this.toStatus(account.settings.onboarding.isContactExported),
              },
            ];
          }

          return [
            {
              step: 'installExtension',
              status: this.toStatus(account.settings.onboarding.isExtensionInstalled),
            },
            {
              step: 'revealContact',
              status: this.toStatus(account.settings.onboarding.isContactRevealed),
            },
            {
              step: 'inviteTeamMember',
              status: this.toStatus(account.settings.onboarding.isTeamMemberInvited),
            },
            {
              step: 'exportContact',
              status: this.toStatus(account.settings.onboarding.isContactExported),
            },
          ];
        },
      ),
    );
  }

  public completeInviteTeamMemberStep(): Promise<void> {
    return this.completeStep('inviteTeamMember');
  }

  public completeExportContactStep(): Promise<void> {
    return this.completeStep('exportContact');
  }

  public completeSignupStep(): Promise<void> {
    return this.completeStep('signup', {
      skipReplication: true,
    });
  }

  public completeClickDownloadExtensionStep(): Promise<void> {
    return this.completeStep('clickDownloadExtension', { skipReplication: true });
  }

  public completeViewDashboardStep(): Promise<void> {
    return this.completeStep('viewDashboard', { skipReplication: true });
  }

  public completeViewInstallExtensionPageStep(): Promise<void> {
    return this.completeStep('viewInstallExtensionPage', { skipReplication: true });
  }

  public async completeMobileSignupOpenChromeStoreStep(): Promise<void> {
    const isExtensionInstalled = await firstValueFrom(
      this.extensionRepository.isExtensionInstalled(),
    );

    if (isExtensionInstalled) return;
    if (!isMobile()) return;

    await this.completeStep('mobileSignupOpenChromeStore', {
      skipReplication: true,
    });

    window.location.href = EXTERNAL_ROUTES.EXTENSION_URL;
  }
}
