import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { useAccount } from '@/features/common/account';
import { EmailSendRateLimitError, useAuthUseCase } from '@/features/common/auth';
import { useSignUpUseCase } from '@/features/signup';
import { ANALYTICS_EVENTS, useAnalytics } from '@/features/system/analytics';
import { useAppLogger } from '@/features/system/logger';

import { useLockTimer } from './hooks';

interface IEmailVerification {
  email: string;
  lockedUntil: number | null;
  handleChangeEmail(): void;
  resendCode(): void;
  onComplete(): void;
}

const LOCK_DURATION_MINUTES = 5;

const useEmailConfirmationViewModel: () => IEmailVerification = () => {
  const { t } = useTranslation('auth');
  const snackbar = useSnackbar();
  const { account } = useAccount();
  const { trackEvent } = useAnalytics();
  const resendLockTimer = useLockTimer(LOCK_DURATION_MINUTES);
  const logger = useAppLogger();

  const authUseCase = useAuthUseCase();
  const signUpUseCase = useSignUpUseCase();

  const email = account?.unconfirmedEmail ?? '';

  useEffect(() => {
    trackEvent(ANALYTICS_EVENTS.VIEW_VERIFICATION_PAGE);
  }, []);

  const handleChangeEmail = async (): Promise<void> => {
    await authUseCase.signOut();
  };

  const handleErrorOfSendEmail = (error: unknown): void => {
    logger.error(error);

    if (error instanceof EmailSendRateLimitError) {
      resendLockTimer.lock();
    }
  };

  const resendCode = async (): Promise<void> => {
    if (resendLockTimer.isLocked || !email) {
      return;
    }

    try {
      await signUpUseCase.sendEmailVerification(email);
      snackbar.enqueueSnackbar(t('verification.resent'), {
        variant: 'success',
      });
    } catch (error) {
      handleErrorOfSendEmail(error);
    }
  };

  return {
    email,
    handleChangeEmail,
    resendCode,
    lockedUntil: resendLockTimer.lockedUntil,
    onComplete: resendLockTimer.onTimerComplete,
  };
};

export default useEmailConfirmationViewModel;
