import { IReciept } from '../../../domain/types';

import { useFormatCurrency } from './useFormatCurrency';

type UseTotalPrice = ({ total }: { total: IReciept['total'] | undefined }) => {
  total: number;
  totalFormatted: string;
};

export const useTotalPrice: UseTotalPrice = ({ total }) => {
  const format = useFormatCurrency();

  if (!total) return { total: 0, totalFormatted: '0' };

  return {
    total: total,
    totalFormatted: format(total),
  };
};
