import React, { useEffect, useMemo, useState } from 'react';

import { useTagEntities } from '@/features/common/tag';
import { ANALYTICS_EVENTS, useAnalytics } from '@/features/system/analytics';

import { useDocumentMeta } from '@/hooks';

import { useAcceptedTeamMembers } from '../../teamManagement';

export type TagTableRowData = {
  uuid: string;
  name: string;
  color: string;
  contacts: number;
  createdBy: string;
  id: string;
  createdAt: number;
};

const useTagManagementViewModel = () => {
  const [searchValue, setSearchValue] = useState('');

  const { data: tagList } = useTagEntities();

  const { teamMembers } = useAcceptedTeamMembers();
  const { trackEvent } = useAnalytics();

  useDocumentMeta({
    title: 'tagsManagement.title',
    description: 'tagsManagement.description',
  });

  useEffect(() => {
    trackEvent(ANALYTICS_EVENTS.TAG_MANAGEMENT_VIEWED);
  }, []);
  const uuidNameEmailMap = useMemo(() => {
    return teamMembers.reduce(
      (acc, member) => {
        acc[member.uuid] = member.fullName || member.email;
        return acc;
      },
      {} as Record<string, string>,
    );
  }, [teamMembers]);

  const mappedTagList = useMemo(() => {
    return tagList.map((tag) => ({
      uuid: tag.uuid,
      name: tag.name,
      color: tag.color,
      contacts: tag.contactsAmount || 0,
      createdBy: uuidNameEmailMap[tag.createdBy] || '',
      createdByUuid: tag.createdBy || '',
      id: tag.uuid,
      createdAt: tag.createdAt,
    }));
  }, [tagList, uuidNameEmailMap]);

  const filteredTagList = useMemo(() => {
    if (!searchValue) {
      return mappedTagList;
    }

    return mappedTagList.filter((tag) =>
      tag.name.toLowerCase().includes(searchValue.toLowerCase()),
    );
  }, [mappedTagList, searchValue]);

  const onSearchChange: React.ChangeEventHandler<HTMLInputElement> = ({
    target: { value },
  }) => {
    setSearchValue(value);
  };

  const onSearchClear = (): void => {
    setSearchValue('');
  };

  return {
    tagTableList: filteredTagList,
    searchValue,
    onSearchChange,
    onSearchClear,
  };
};

export default useTagManagementViewModel;
